
<h5 class="contentHeading">Inspection Details </h5>
<div>
  <div class="contentRow"><p>Site Name</p><p>{{ data[1].Details[0].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Site Code</p><p>{{ data[1].Details[1].Value || 'N/A'}}</p></div>
  <div class="contentRow" ><p>Site Status</p><p>{{ data[1].Details[2].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Facility Address</p><p>{{ data[1].Details[3].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>Audit Date</p><p>{{ data[1].Details[6].Value || 'N/A'}}</p></div>
  <div class="contentRow"><p>GPS Latitude, Longitude</p><p>{{ data[1].Details[7].Value?.replace(" ",', ')|| 'N/A'}}</p></div>
  <div class="contentRow"><p>Tower Type</p><p>{{ data[1].Details[8].Value || 'N/A' }}</p></div>
  <div class="contentRow"><p>Tower Height</p><p>{{ data[1].Details[9].Value || 'N/A' }}</p></div>
</div>

