import { Component, OnInit, ViewChild } from '@angular/core';
import { OrthomapFetchService } from './orthomap-fetch.service';
import { ActivatedRoute } from "@angular/router";
import { NgxCaptureService } from 'ngx-capture';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/storage';
import html2canvas from 'html2canvas';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-orthomap',
  templateUrl: './orthomap.component.html',
  styleUrls: ['./orthomap.component.css']
})
export class OrthomapComponent implements OnInit {
  @ViewChild("polyline") polyline;
  @ViewChild("result") result;
  @ViewChild("mapContainer",{ static: false }) mapContainer : any;
  map;
  isLoaded = false;
  towerName: string;
  mapBounds;
  disableUI = false;
  mapTypeControl = true;
  boundValues;
  zoom = 19;
  minZoom = 17;
  maxZoom = 22;
  opacity = 100;
  addAnnotation = false;
  removeAnnotation = false;
  mode = 1;
  value = 0;
  icon = {
    path: 'M -2,-2 2,-2 2,2 -2,2 z',
    fillColor: '#00000',
    fillOpacity: 1,
    scale: 2,
  }
  canvas;
  markers = [];
  windows = [];
  distance = [];
  latLong1 = { lat: null, lng: null };
  latLong2 = { lat: null, lng: null };
  url = null;
  urlData;
  co;
   zo;
  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private service: OrthomapFetchService, private capture: NgxCaptureService, private storage: AngularFireStorage) {

    this.getData();

  }

  ngOnInit(): void {

  }


  getData() {
    this.route.params.subscribe(params => {
      this.towerName = params.tower;

    });
    this.service.fetchData(this.towerName).subscribe(
      (doc) => {
      this.boundValues = doc.data()['OrthoMap'];
      this.latLong1.lat = this.boundValues["Latitude1"];
      this.latLong1.lng = this.boundValues["Longitude1"];
      this.latLong2.lat = this.boundValues["Latitude2"];
      this.latLong2.lng = this.boundValues["Longitude2"];

      this.isLoaded = true;
    },
    (err)=>{
      this.openSnackBar(err, "Close");
    }
    );
  }

  onMapReady(map?: google.maps.Map) {

    this.map = map;
    this.mapBounds = new google.maps.LatLngBounds(new google.maps.LatLng(this.latLong1.lat, this.latLong1.lng), new google.maps.LatLng(this.latLong2.lat, this.latLong2.lng));

    this.map.fitBounds(this.mapBounds);


    var pix4tiler = this.createOverlay(100, this.map, this.mapBounds, this.minZoom, this.maxZoom, this.towerName);
    this.map.overlayMapTypes.insertAt(0, pix4tiler);
  }

  createOverlay(percent, map, mapBounds, minZoom, maxZoom, towerName) {
    var pix4tiler = new google.maps.ImageMapType({
      getTileUrl: function (coord, zoom) {
        var proj = map.getProjection();
        var tileSize = 256 / Math.pow(2, zoom);
        var tileBounds = new google.maps.LatLngBounds(proj.fromPointToLatLng(new google.maps.Point(coord.x * tileSize, (coord.y + 1) * tileSize)), proj.fromPointToLatLng(new google.maps.Point((coord.x + 1) * tileSize, coord.y * tileSize)));

        if (mapBounds.intersects(tileBounds) && (zoom >= minZoom) && (zoom <= maxZoom)) {
          let url;
        url = "https://firebasestorage.googleapis.com/v0/b/" + "vertikaliti-pwc.appspot.com"+ "/o/" + 'OrthoMap' + '%2F' + towerName + '%2F'+ zoom  + '%2F' + coord.x + '%2F' + (Math.pow(2, zoom) - coord.y - 1) +  ".png?alt=media&token=ugi";
          return url;
        }
          return 'https://none.png';
      }, tileSize: new google.maps.Size(256, 256), opacity: percent / 100
    });
    return pix4tiler;
  }


  ngOnChanges() {
  }

  addMarker(event) {
    if ((this.markers.length == undefined || this.markers.length == 0) && this.mode == 2) {
      this.clear();
      this.openSnackBar("2 Markers Minumum Needed to Switch to Area Mode", "Close");
      return;
    }
    if (this.addAnnotation) {
      if (this.mode == 1) {
        this.markers.push({
          lat: event["coords"].lat,
          lng: event["coords"].lng
        })
        this.getDistance();
        this.setWindow();
      }
      if (this.mode == 2) {

        if (this.markers[0].lat == this.markers[this.markers.length - 1].lat && this.markers[0].lng == this.markers[this.markers.length - 1].lng) {
          this.markers.pop();
        }
        this.markers.push({
          lat: event["coords"].lat,
          lng: event["coords"].lng
        });
        this.markers.push({
          lat: this.markers[0].lat,
          lng: this.markers[0].lng
        });
        this.getDistance();
        this.setWindow();
        this.getArea();
      }
    }
  }

  setWindow() {
    this.windows = [];
    let prev, crnt, center;
    for (let i = 0; i < this.markers.length; i++) {
      if (i == this.markers.length - 1) {
        break;
      } else {
        prev = new google.maps.LatLng(this.markers[i].lat, this.markers[i].lng);
        crnt = new google.maps.LatLng(this.markers[i + 1].lat, this.markers[i + 1].lng);
      }
      let temp = google.maps.geometry.spherical.interpolate(prev, crnt, 0.5);
      center = {
        lat: temp.toJSON().lat,
        lng: temp.toJSON().lng,
        distance: this.distance[i]
      };
      this.windows.push(center);
    }
  }



  removeMarker(event) {
    if (this.removeAnnotation) {
      this.markers = this.markers.filter(elt => { return (elt.lat !== event["latitude"] && elt.lng !== event["longitude"]) });
      if (this.mode == 1) {
        this.getDistance();
        this.setWindow();
      } else {
        this.getDistance();
        this.setWindow();
        this.getArea();
      }
    }
  }

  toggle(elt) {
    if (elt === "add") {
      if (this.removeAnnotation == true && this.addAnnotation == false) {
        this.removeAnnotation = false;
      }
      this.addAnnotation = !this.addAnnotation;
    } else {
      if (this.addAnnotation == true && this.removeAnnotation == false) {
        this.addAnnotation = false;
      }
      this.removeAnnotation = !this.removeAnnotation;
    }
  }

  changeMode(event) {
    if (this.markers.length) {
      this.mode = event.value;
      if (this.mode == 1) {
        if (this.markers[0].lat == this.markers[this.markers.length - 1].lat && this.markers[0].lng == this.markers[this.markers.length - 1].lng) {
          this.markers.pop();
        }
        this.getDistance();
        this.setWindow();
      }
      if (this.mode == 2 && this.markers.length >= 2) {
        if (this.markers[0].lat != this.markers[this.markers.length - 1].lat && this.markers[0].lng != this.markers[this.markers.length - 1].lng) {
          this.markers.push({
            lat: this.markers[0].lat,
            lng: this.markers[0].lng
          });
        }
        this.getDistance();
        this.setWindow();
        this.getArea();
      }
    } else if (this.mode != 1 && this.mode != 2) {
      this.openSnackBar("To Add Annotations, Turn On Add Annotations Toggle!", "Close")
    } else if ((this.markers.length == undefined || this.markers.length == 0) && this.mode == 2) {
      this.clear();
      this.openSnackBar("2 Markers Minumum Needed to Switch to Area Mode", "Close");
    }

  }

  getDistance() {
    this.distance = [];
    let currentDistance = 0;
    let prev;

    this.markers.forEach(elt => {
      if (prev) {
        currentDistance += parseFloat(google.maps.geometry.spherical.computeDistanceBetween(prev, new google.maps.LatLng(elt.lat, elt.lng)).toFixed(2));
        this.distance.push(currentDistance);
      }
      prev = new google.maps.LatLng(elt.lat, elt.lng);
    })
    this.value = parseFloat(currentDistance.toFixed(2));
  }

  getArea() {
    let areaArray = [];
    this.markers.forEach(elt => {
      areaArray.push(new google.maps.LatLng(elt.lat, elt.lng));
    })
    this.value = parseFloat(google.maps.geometry.spherical.computeArea(areaArray).toFixed(2));
  }

  changeOpacity() {
    this.map.overlayMapTypes.clear();
    var pix4tiler = this.createOverlay(this.opacity, this.map, this.mapBounds, this.minZoom, this.maxZoom, this.towerName);
    this.map.overlayMapTypes.insertAt(0, pix4tiler);
  }

  clear() {
    this.markers = [];
    this.value = 0;
    this.mode = 1;
  }

  printing() {
    this.disableUI = true;
    this.mapTypeControl = false;
    // setTimeout(()=>{
    //   this.capture.getImage(this.mapContainer._elem.nativeElement, true).pipe(
    //     map(img => {
    //       return img;
    //     })
    //   ).subscribe((img) => {
    //     let link = document.createElement("a");
    //     link.href = img;
    //     link.download = "image.jpg";
    //     link.click();

    //   });
    // },1000)

    html2canvas(this.mapContainer._elem.nativeElement,{ allowTaint: false, useCORS: true,imageTimeout : 22500}).then(canvas => {
      this.canvas = document.createElement("canvas");
      this.canvas.src = canvas.toDataURL();
               let link = document.createElement("a");
         link.href = canvas.toDataURL('image/png');
         link.download = "image.jpg";
         link.click();
    })

  }


  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


}
