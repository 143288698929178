import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DigitaltwinFetchService {
url;
  constructor(private fireStore : AngularFirestore,private http :  HttpClient,public sanitizer: DomSanitizer,private storage : AngularFireStorage) { }


fetchData(tower){
  return this.http.get("assets/json/"+tower+"/digitaltwin.json");
  }


  setData(tower){
    return  this.fireStore.collection(tower).doc(tower);
    }

    towersData(year){
      return this.fireStore.collection('towers').doc(year);
    }

loadGLTF(link){
 return this.http.get(link);
}



}
