<h1 mat-dialog-title>Add Details</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Item</mat-label>
    <input matInput [(ngModel)]="item" required>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <input matInput [(ngModel)]="description" required>
  </mat-form-field>
    <ng-container *ngIf="description">
      <mat-form-field appearance="fill">
        <mat-label>Select a Option</mat-label>
      <mat-select [(ngModel)]="issue" required>
        <mat-option [value]="1" selected>Monitor</mat-option>
        <mat-option [value]="2">Planned</mat-option>
        <mat-option [value]="3">Urgent</mat-option>
        <mat-option [value]="4">Emergency</mat-option>
      </mat-select>
    </mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="resultFn()" cdkFocusInitial class="submit">Ok</button>
</div>
