import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  hide = true;
  constructor(private service : AuthService) { }

  ngOnInit(): void {
  }

  signin(email,password){
    this.service.SignIn(email,password);
  }

}
