import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../auth.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  hide = true;
  link = false;
  constructor(private service : AuthService, private snackBar : MatSnackBar) { }

  ngOnInit(): void {
  }

  signup(email,password,repassword,username,role){
    if(password === repassword){
      this.service.SignUp(email,password,username,role);
      this.link = true;
    }else{
      this.snackBar.open("Passwords doesn't match!", "close");
    }
  }

//   resendVerification(){
// this.service.SendVerificationMail();
//   }

}
