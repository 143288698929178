import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class NavbarFetchService {

  constructor(private fireStore : AngularFirestore) { }

  fetchData(towerName){
    return this.fireStore.collection(towerName).get();
}



fetchTowers(){
  return this.fireStore.collection('towers').get();
 }


//  fetchYear(year){
//   return this.fireStore.collection('towers').doc(year).get();
//  }

}
