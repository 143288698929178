<h5 class="contentHeading">Categories </h5>
<div class="contentBody"  *ngIf="data">
 <div class="box complied" (click)="viewAudit('Monitor')">
   <p class="boxNumber">{{ eltfn("monitor") }}</p>
   <p class="boxText">Monitor</p>
 </div>
 <div class="box minor" (click)="viewAudit('Planned')">
   <p class="boxNumber">{{ eltfn("planned") }}</p>
   <p class="boxText">Planned</p>
 </div>
 <div class="box major" (click)="viewAudit('Urgent')">
   <p class="boxNumber">{{ eltfn("urgent") }}</p>
   <p class="boxText">Urgent</p>
  </div>
  <div class="box critical" (click)="viewAudit('Emergency')">
   <p class="boxNumber">{{ eltfn("emergency") }}</p>
   <p class="boxText">Emergency</p>
   </div>
