import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './../../auth.service';

@Injectable({
  providedIn: 'root'
})
export class PilotGuard implements CanActivate {

  constructor(private service: AuthService,private router: Router){ }

  canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {


    return this.authenticate();
  }

  authenticate() {
    let role;
    let service = this.service.userRole();
    if(service){
      return service.pipe(map(
        (res) => {
          let data = res.data();
          role = data['role'];
          if (this.service.isLoggedIn !== true || role !== 'pilot') {
            this.router.navigate(['sign-in']);
          }
          return true;
        }))
    }else{
      this.router.navigate(['sign-in']);
    }
  }

}
