import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuditFetchService {
  count = 0;
  auditData = [];
  audit_data = [];
  audit_data1 = [];
  constructor(private fireStore : AngularFirestore) { }


  fetchData(towerName){
    return this.fireStore.collection(towerName).get();
}



 setData(tower){
 return  this.fireStore.collection(tower).doc(tower)
 }

 towersData(year){
   return this.fireStore.collection('towers').doc(year);
 }

 dataBuild(data, data1){
  this.audit_data.push(data, data1);
  this.audit_data1= data1;
  // console.log(this.audit_data);
  
 }
 excelBuild(){
  let obj = []
  obj.push(this.audit_data[0][0].Details)
  obj.push(this.audit_data[0][1].Details)

  console.log('hello');
  
  console.log(obj);
  
 return obj

 }

 excelBuildHeader(data, n){
   let header = []
   console.log(data);
   
   console.log(Object.keys(data[n]));
   let obj = Object.keys(data[n])
   for(let i = 0; i< obj.length; i++){
     console.log(obj[i]);
     
     if(obj[i] !== 'Details'){
       console.log('it is');
       
       header.push(obj[i])
     }

   }
   console.log(header);
   
   return header;
 }

}
