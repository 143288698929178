import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material/snack-bar';



export interface User {
  uid: string;
  email: string;
  displayName: string;
  role: string;
  password: string;
}


@Injectable({
  providedIn: 'root'
})



export class AuthService {
  userState: any;
  role;
  constructor(private fireAuth: AngularFireAuth, private fireStore: AngularFirestore, private router: Router, private ngZone: NgZone, private snackBar: MatSnackBar) {
    this.fireAuth.authState.subscribe(user => {
      if (user) {
        this.userState = user;
        localStorage.setItem('user', JSON.stringify(this.userState));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })

  }

  SignIn(email, password) {
    return this.fireAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          this.fireStore.collection('users').valueChanges().subscribe(
            (data) => {
              data.forEach(elt => {
                if (result.user.uid === elt['uid']) {
                  this.role = elt['role'];
                  let role = this.role;
                  if (this.isLoggedIn !== true) {
                    this.router.navigate(['sign-in']);
                  }
                  if (role == "telstra-processing") {
                    this.router.navigate(['telstra-processing/map']);
                  }
                  if (role == "pilot") {
                    this.router.navigate(['pilot/map']);
                  }
                  if (role == "aero-processing") {
                    this.router.navigate(['aero-processing/map']);
                  }
                  if (role == "user") {
                    this.router.navigate(['user/map']);
                  }
                  if (role == "super-admin") {
                    this.router.navigate(['super-admin/map']);
                  }
                  if (role == "unset") {
                    this.router.navigate(['unset']);
                  }

                }
              });
            },
            (err) => {
              this.snackBar.open(err, "close", { duration: 2000 });
            }
            );
        });
      }).catch((error) => {
        this.snackBar.open('Email/Password is Incorrect!' + error, "close", { duration: 2000 });
      })
  }

  SignUp(email, password, username,role) {
    return this.fireAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        //    this.SendVerificationMail();
        if (result.user) {
          this.snackBar.open('Email is Registered!', "close", { duration: 2000 });
          this.SetUserData(result.user, username,role, password);
        }
      }).catch((error) => {
        this.snackBar.open(error.message, "close", { duration: 2000 });
        if (error.code === "auth/email-already-in-use") {
          setTimeout(() => {
            this.router.navigate(['sign-in']);
          }, 2000);
        }
      })
  }

  SetUserData(user, username,role, password) {
    const userRef: AngularFirestoreDocument<any> = this.fireStore.doc(`users/${user.uid}`);
    const userState: User = {
      uid: user.uid,
      email: user.email,
      displayName: username,
      role: role,
      password: password
    }
    return userRef.set(userState, {
      merge: true
    })
  }


  //   SendVerificationMail() {
  //     return this.fireAuth.currentUser.then(u => u.sendEmailVerification())
  //     .then(() => {
  //       this.snackBar.open('Verification Link has been Sent to your Email!', "close",{ duration: 2000});
  //     })
  // }


  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
     if(user) {
      return true;
    }
    return false;
  }

  userRole() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
     return this.fireStore.collection('users').doc(user.uid).get();
    }
  }



  ForgotPassword(passwordResetEmail) {
    return this.fireAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.snackBar.open('Password reset email sent, check your inbox!', "close", { duration: 2000 });
      }).catch((error) => {
        this.snackBar.open(error.message, "close", { duration: 2000 });
      })
  }

  SignOut() {
    return this.fireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    })
  }

}
