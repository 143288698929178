import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ImageSliderComponent implements OnInit {

  @Input() loading;
  @Input() payload;
  @Input() crntDir;
  @Input() index;
  @Input() sliderIndex;
  @ViewChild("slider") slider;
  count = 0;

  wheelOffset = -100;
  scrollSpeed = 400;

  @Output() sliderEvent = new EventEmitter<any>();

  @Output() contentLoaded = new EventEmitter<any>();


  constructor() { }

  ngOnInit(): void {
  }
  ngOnAfterContentInit(){


  }

  ngOnChanges(){
    if(this.loading == false){
      setTimeout(()=>{
        this.setSlider();
      },3000)
    }else if(this.slider){
     // this.selectImage();
      this.setSlider();
    }
  }

setSlider(){
    let crntImage = this.slider.nativeElement.querySelector("#thumb_" + this.index);
    if(crntImage){
      crntImage.scrollIntoView({
        behavior: 'smooth',
        inline: 'center'
      });
      this.sliderIndex = false;
      this.sliderEvent.emit({index:  this.index, sliderIndex : this.sliderIndex});
    }
    this.loading = true;
}


  ngAfterViewInit() {
  }

  handleScroll(event) {
    if (event.deltaY === this.wheelOffset) {
      this.slider.nativeElement.scrollLeft += this.scrollSpeed;
    } else {
      this.slider.nativeElement.scrollLeft -= this.scrollSpeed;
    }
  }


  clickImage(index) {
    this.index = parseInt(index);
    this.sliderIndex = false;
    this.sliderEvent.emit({index:  this.index, sliderIndex : this.sliderIndex});
   // this.selectImage();
  }

  // selectImage() {
  //   let prevImage = this.slider.nativeElement.querySelector(".selected");
  //   if(prevImage){
  //     prevImage.removeAttribute("class");
  //   }
  //   let crntImage = this.slider.nativeElement.querySelector("#thumb_" + this.index);
  //   crntImage.setAttribute("class", "selected");
  // }

}
