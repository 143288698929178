<div class="imageContainer row" #imageContainer (dragover)="dragImage($event)" (dragend)="endDrag($event)">
  <!-- -->
  <!-- <div class="leftContainer  d-flex flex-column justify-content-between col-md-3">
    <div class="container d-flex flex-column justify-content-center">
    </div>

  </div> -->
  <div class="col-md-9 container-fluid d-flex justify-content-center imageBox" (wheel)="wheelZoom($event)">

    <div class="pan-zoom-frame">
      <div class="pan-element" #container>
      <div class="rulerDiv" *ngIf="view == 'Los123'">
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle - 3).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle - 2).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle - 1).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle + 1).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle + 2).toFixed(2)}}</span>
          </div>
          <div class="ruler">
            <img class="rule" src="assets/images/map/rule1F.png">
            <span>{{(payload[crntDir].Payload[index].angle + 3).toFixed(2)}}</span>
          </div>
        </div>
        <div class="zoom_element">
          <div style="position: relative; width: 100%; height: 100%;display: flex;justify-content: center;align-items: center;">
            <img [ngClass]="{'invisible' : loading == false}" (load)="loadImage($event)" #image class="image"
              [src]="payload[crntDir].Payload[index].file">
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="rightContainer col-md-3">
    <div class="d-flex  flex-column justify-content-center align-items-center">
      <h2 class="levelHeading">View Levels</h2>
      <div class="viewList">
        <ng-container *ngFor="let elt of payload;let i = index">
          <div class="crntView" class="rows" [ngClass]="{ 'selectedView' : crntDir == i  }">
            <p class="dirText" (click)="crntDrty(i)">{{ elt.Directory }}</p>
            <i (click)="editDir(i,elt.Directory)"
              *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">
              <mat-icon [ngClass]="{ 'selectededit' : crntDir == i  }" class="editDir">edit</mat-icon>
            </i>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column justify-content-center heightDiv">
      <sub class="center sliderTitle">Image Slider</sub>
      <input type="range" [ngClass]="{'invisible' : loading == false}" min="0"
        [max]="(payload[crntDir].Payload.length -1)" [value]="index" class="slider" #range
        (input)="changeImage(range.value)" (change)="setIndex(range.value,true)" placeholder="Image Slider">
      <!-- (input)="setIndex(range.value)"-->
    </div>
  </div>
</div>
