import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SpinModelServiceService {

  constructor(private fireStore : AngularFirestore) { }

  fetchData(towerName){
    return this.fireStore.collection(towerName).doc(towerName).get();
}

setData(tower){
  return  this.fireStore.collection(tower).doc(tower);
  }

}
