import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class TableFetchService {

  constructor(private fireStore : AngularFirestore) { }


  fetchTowers(){
    return this.fireStore.collection('towers').get();
   }
   addTower(tower){
    return this.fireStore.collection(tower).doc(tower);
   }


   setData(year){
   return this.fireStore.collection('towers').doc(year);
   }


}
