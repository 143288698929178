<mat-card class="signUpcard">
  <mat-card-header>
    <mat-card-title>Sign Up</mat-card-title>
    <mat-card-subtitle>Enter your Details to Register</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="signUpForm">
      <mat-form-field appearance="fill">
        <mat-label>Enter your Username</mat-label>
        <input matInput name="email" type="text" #username required>
        <button mat-icon-button matSuffix>
          <mat-icon>email</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Enter your Email</mat-label>
        <input matInput name="email" type="email" #email required>
        <button mat-icon-button matSuffix>
          <mat-icon>email</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" minlength="6" maxlength="25" #password required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Re-Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'" minlength="6" maxlength="25" #repassword required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
          <mat-label>Role</mat-label>
          <mat-select #role>
            <mat-option [value]="'user'">Guest</mat-option>
            <mat-option [value]="'pilot'">Pilot</mat-option>
            <mat-option [value]="'telstra-processing'">Telstra Processing</mat-option>
            <mat-option [value]="'aero-processing'">Aero Processing</mat-option>
            <mat-option [value]="'super-admin'">Super Admin</mat-option>
          </mat-select>
          <button mat-icon-button matSuffix>
            <mat-icon>account_circle</mat-icon>
          </button>
        </mat-form-field>
    </div>
    <div class="else">
    <div class="registered">
      <span>Registered?<span class="redirect" routerLink="/sign-in"> Login</span></span>
    </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="signup(email.value,password.value,repassword.value,username.value,role.value)" class="signUpBtn">Register</button>
      </mat-card-actions>
</mat-card>
