import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class MapFetchService {
  constructor(private fireStore : AngularFirestore) { }


fetchData(){
 return this.fireStore.collection('towers').get();
}

}
