<mat-card class="forgotPasswordCard">
  <mat-card-header>
    <mat-card-title>Reset Password</mat-card-title>
    <mat-card-subtitle>Enter your Email to Reset your Password</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="forgotPasswordForm">
      <mat-form-field appearance="fill">
        <mat-label>Enter your Email</mat-label>
        <input matInput name="email" type="email" #email required>
        <button mat-icon-button matSuffix>
          <mat-icon>email</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="forgotPassword(email.value)" class="resetBtn">Reset</button>
      </mat-card-actions>
</mat-card>
