import { Component, OnInit, ViewChild } from '@angular/core';
import { TableFetchService } from './table-fetch.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { EditComponent } from './../edit/edit.component'
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TowerComponent } from './../tower/tower.component';


export interface dataTable {
  Tower: string,
  Type: string,
  SiteID: number,
  Region: string,
  Status: string,
  Completed: string,
  Latitude: number,
  Longitude: number,
  Corrosion: string,
  // StructureType: string,
  // CanradVariation: string,
  Emergency: number,
  Urgent: number,
  Planned: number,
  Monitor: number
}


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  // displayedColumns: string[] = ['No', 'Tower', 'TowerType', 'SiteID', 'Region', 'Status', 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'StructureType', 'CanradVariation', 'Emergency', 'Urgent', 'Planned', 'Monitor'];//
  displayedColumns: string[] = ['No', 'Tower', 'TowerType', 'SiteID', 'Region', 'Status', 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'Emergency', 'Urgent', 'Planned', 'Monitor'];//
  data = [];
  dataSource: MatTableDataSource<dataTable>;
  crntYear = 0;
  role;
  selectedStatus = '';
  isLoaded = false;
  summariesCSV;
  filterPredicate;
  detailsCSV;
  count = 0;
  towerName;
  result;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('status') status;

  constructor(private service: TableFetchService, private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
  }

  ngAfterViewInit() {
    this.service.fetchTowers().forEach(data => {
      data.forEach(doc => {
        this.data.push(doc.data());
      })
    }).then(() => {
      this.dataSource = new MatTableDataSource(this.data[this.crntYear]['Towers']);
      this.filterPredicate = this.dataSource.filterPredicate;
      setTimeout(() => this.dataSource.sort = this.sort);
      setTimeout(() => this.dataSource.paginator = this.paginator);
      this.isLoaded = true;
      this.summariesCSV = ['Tower', 'TowerType', 'SiteID', 'Region', 'Status', , 'Completed', 'Latitude', 'Longitude', 'Corrosion', 'Structure Type', 'Canrad Variation', 'Emergency', 'Urgent', 'Planned', 'Monitor'].join(",") + "\n";
      for (let j = 0; j < this.data[this.crntYear]['Towers'].length; j++) {
        this.summariesCSV += this.data[this.crntYear]['Towers'][j]["Tower"] + ',' + this.data[this.crntYear]['Towers'][j]["Type"] + ',' + this.data[this.crntYear]['Towers'][j]["SiteID"] + ',' + this.data[this.crntYear]['Towers'][j]["Region"] + ',' + this.data[this.crntYear]['Towers'][j]["Status"] + "," + this.data[this.crntYear]['Towers'][j]["Completed"] + ',' + this.data[this.crntYear]['Towers'][j]["Latitude"] + ',' + this.data[this.crntYear]['Towers'][j]["Longitude"] + ',' + this.data[this.crntYear]['Towers'][j]["Corrosion"] + ',' + this.data[this.crntYear]['Towers'][j]["Emergency"] + ',' + this.data[this.crntYear]['Towers'][j]["Urgent"] + ',' + this.data[this.crntYear]['Towers'][j]["Planned"] + ',' + this.data[this.crntYear]['Towers'][j]["Monitor"] + "\n";
      }
    });

  }

  switchStatus(event) {
    console.log(event.target.value)
    this.sort.sort(({ id: 'Status' }) as MatSortable);
    this.sort.direction = event.target.value;
    this.sort.disableClear = false;
    this.dataSource.sort = this.sort;
  }

  inspectedList(elt) {
    return elt.Status === 'Inspected';
  }

  unInspectedList(elt) {
    return elt.Status === 'Uninspected';
  }

  switchTable() {
    this.isLoaded = false;
    this.dataSource = new MatTableDataSource(this.data[this.crntYear]['Towers']);
    this.filterPredicate = this.dataSource.filterPredicate;
    setTimeout(() => this.dataSource.sort = this.sort);
    setTimeout(() => this.dataSource.paginator = this.paginator);
    this.isLoaded = true;
  }


  downloadCSV(csv, Name) {
    let downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    downloadLink.target = '_blank';
    downloadLink.download = Name + '.csv';
    downloadLink.click();
  }

  getTotal(issue) {
    this.count = 0;
    this.data[this.crntYear]['Towers'].forEach(record => {
      if (record[issue]) {
        this.count += parseInt(record[issue])
      }
    });
    return this.count;
  }
  applyStatusFilter() {
    //  this.dataSource.filterPredicate = this.filterPredicate;

    let filterValue = this.selectedStatus;
    this.dataSource.filter = filterValue;
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      if (this.dataSource.filter === 'inspected') {

      }
      this.dataSource.paginator.firstPage();

    }
  }


  applyFilter(event: Event) {

    // this.dataSource.filterPredicate =
    // (data: dataTable, filter: string) => !filter || data.Status == filter;

    let filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim();
    this.status.nativeElement.click();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  clickEvent(value, event, condition, id, field) {
    event.stopPropagation();
    if (condition == false) {
      if (id == 'Inspected') {
        this.router.navigate(['/' + this.role + '/' + this.data[this.crntYear].Year + '/' + value.replaceAll(' ', '') + '/summary']);
      } else {
        this.snackBar.open('This Site is not Inspected yet!', "close", { duration: 2000 });
      }
    }
    if (condition == true && id != null) {
      const dialogRef = this.dialog.open(EditComponent, {
        width: '300px',
        data: { value: value, name: field }
      });
      dialogRef.afterClosed().subscribe(
        result => {
          this.result = result.value;
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          this.data[this.crntYear]['Towers'].forEach(elt => {
            if (elt.SiteID == id) {
              elt[field] = this.result;
            }
          });
          this.service.setData(this.data[this.crntYear].Year).set(
            this.data[this.crntYear],
            {
              merge: true
            }
          );

          this.switchTable()

        }
      );
    }
  }


  editTower(sideID, mode,event) {
    console.log(mode)
    event?.stopPropagation();
    if (mode === 'add') {
      const dialogRef = this.dialog.open(TowerComponent, {
        width: '450px',
        maxHeight: '980px',
        data: {}
      });
      dialogRef.afterClosed().subscribe(
        (result) => {
          if(result){
            this.result = result;
          }
        },
        (err) => {
          this.snackBar.open(err, "close", { duration: 2000 });
        },
        () => {
          this.data[this.crntYear]['Towers'].push(this.result.TowersData);
          this.service.setData(this.data[this.crntYear].Year).update({
            Towers: this.data[this.crntYear]['Towers']
          }).catch((err)=>{
            this.snackBar.open(err, "close", { duration: 2000 });
          })
          this.service.addTower(this.result.TowersData.Tower).set(this.result.Tower).catch((err)=>{
            this.snackBar.open(err, "close", { duration: 2000 });
          })
        });
    }
    if (mode === 'delete') {
      for (let i = 0;i < this.data[this.crntYear]['Towers'].length;i++) {
       if(this.data[this.crntYear]['Towers'][i].SiteID === sideID){
        console.log('delete')
        this.data[this.crntYear]['Towers'].splice(i, 1);
       }
      }
        this.service.setData(this.data[this.crntYear].Year).update({
          Towers: this.data[this.crntYear]['Towers']
        }).catch((err)=>{
          this.snackBar.open(err, "close", { duration: 2000 });
        })

    }
  }

}
