<h1 mat-dialog-title>Add Section</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>Select Section Type</mat-label>
    <mat-select [(ngModel)]="section" required>
      <mat-option [value]="'General'">General</mat-option>
      <mat-option [value]="'Equipment'">Equipment</mat-option>
    </mat-select>
  </mat-form-field>
    <ng-container *ngIf="section === 'Equipment'">
      <mat-form-field appearance="fill">
        <mat-label>Select a Leg</mat-label>
      <mat-select [(ngModel)]="leg" required>
        <mat-option [value]="'Leg 1'">Leg 1</mat-option>
        <mat-option [value]="'Leg 2'">Leg 2</mat-option>
        <mat-option [value]="'Leg 3'">Leg 3</mat-option>
        <mat-option [value]="'Leg 4'">Leg 4</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" *ngIf="leg !== 0">
      <mat-label>Select a Equipment</mat-label>
    <mat-select [(ngModel)]="equipment" required>
      <mat-option [value]="'RF'">RF</mat-option>
      <mat-option [value]="'RRU'">RRU</mat-option>
      <mat-option [value]="'MW'">MW</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill" *ngIf="leg !== 0">
    <mat-label>Equipment Name : {{equipment}}</mat-label>
    <input matInput [(ngModel)]="equipmentName" required>
</mat-form-field>
    </ng-container>
    <ng-container *ngIf="section === 'General'">
<mat-form-field appearance="fill">
  <mat-select [(ngModel)]="mode" required>
    <mat-option [value]="'Normal'">Normal</mat-option>
    <mat-option [value]="'CanradVariations'">Canrad Variations</mat-option>
    <mat-option [value]="'CorrosionReport'">Corrosion Report</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field>
  <mat-label>Section Name</mat-label>
  <input matInput [(ngModel)]="sectionName" required>
</mat-form-field>
    </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button [mat-dialog-close]="resultFn()" class="submit">Ok</button>
</div>
