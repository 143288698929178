<mat-chip-list aria-label="Issues selection">
  <mat-chip class="chipOne" value="All" (click)="select($event)">All</mat-chip>
  <mat-chip class="chipTwo" value="Monitor" (click)="select($event)">Monitor</mat-chip>
  <mat-chip class="chipThree" value="Planned" (click)="select($event)">Planned</mat-chip>
  <mat-chip class="chipFour" value="Urgent" (click)="select($event)">Urgent</mat-chip>
  <mat-chip class="chipFive" value="Emergency" (click)="select($event)">Emergency</mat-chip>
  <ng-container *ngIf="role == 'super-admin' || role == 'aero-processing' || role == 'telstra-processing'">
    <mat-chip class="addIcon" (click)="addSection()"><mat-icon>add</mat-icon></mat-chip>
    <mat-chip class="deleteIcon" (click)="deleteSection()"><mat-icon>delete</mat-icon></mat-chip>
  </ng-container>
</mat-chip-list>
<div *ngIf="data" class="content">
  <div *ngIf="selected === 'All'">
    <div *ngIf="data.General" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf=" i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
      <div class="contentHeading" (click)="getDetails('Equipment')">
        <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div class="contentSubHeading">Leg 1</div>
      <div *ngFor="let items of data.Equipment.filter(leg1);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div class="contentSubHeading" >Leg 2</div>
      <div *ngFor="let items of data.Equipment.filter(leg2);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div class="contentSubHeading">Leg 3</div>
      <div *ngFor="let items of data.Equipment.filter(leg3);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      <div class="contentSubHeading">Leg 4</div>
      <div *ngFor="let items of data.Equipment.filter(leg4);let i = index;"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Monitor'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf="items.Details.filter(monitor).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>
        <div class="contentSubHeading" *ngIf="i == 0">Leg 1</div>
        <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
          <div *ngIf="items.Details.filter(monitor).length > 0"
            [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
            <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
              <p>{{i+1}}</p>
              <p>{{ items.Name }}</p>
              <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
              <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
              <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
              <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
              <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
            </div>
          </div>
        </div>
        <div class="contentSubHeading">Leg 2</div>
        <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div class="contentSubHeading">Leg 3</div>
      <div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
      <div class="contentSubHeading">Leg 4</div>
      <div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(monitor).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>
    </div>
  </div>
  <div *ngIf="selected === 'Planned'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(planned).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading"(click)="getDetails('Equipment')">
          <p>No</p>
        <p>Equipment</p>
        <p>Details</p>
      </div>
      <div class="contentSubHeading">Leg 1</div>
      <div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>

<div class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
</div>


<div class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
      <div *ngIf="items.Details.filter(planned).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>

    </div>
  </div>
  <div *ngIf="selected === 'Urgent'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow"  *ngIf=" items.Details.filter(urgent).length > 0 " (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>

        <div class="contentSubHeading">Leg 1</div>
<div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
        [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
        </div>
      </div>
      </div>


      <div class="contentSubHeading">Leg 2</div>
      <div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
      <div *ngIf="items.Details.filter(urgent).length > 0"
      [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
      <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
        <p>{{i+1}}</p>
        <p>{{ items.Name }}</p>
        <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
        <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
        <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
        <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
        <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
      </div>
    </div>
    </div>


    <div class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
    <div *ngIf="items.Details.filter(urgent).length > 0"
    [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
    <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
      <p>{{i+1}}</p>
      <p>{{ items.Name }}</p>
      <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
      <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
      <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
      <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
      <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
    </div>
  </div>
  </div>


  <div class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
  <div *ngIf="items.Details.filter(urgent).length > 0"
  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
  <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
    <p>{{i+1}}</p>
    <p>{{ items.Name }}</p>
    <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
    <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
    <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
    <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
    <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
  </div>
</div>
</div>
    </div>
  </div>
  <div *ngIf="selected === 'Emergency'">
    <div *ngIf="data.General.length" class="contentBox">
      <div *ngFor="let items of data.General;index as i" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
        <div class="contentHeading" *ngIf="i === 0" (click)="getDetails('General')">
          <p>No</p>
          <p>General</p>
          <p>Details</p>
        </div>
        <div class="contentRow" *ngIf=" items.Details.filter(emergency).length > 0" (click)="getDetails(items,'General',i)">
          <p>{{i+1}}</p>
          <p>{{ items.Name }}</p>
          <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
          <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
          <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
          <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
          <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'General')"></span></p>
        </div>
      </div>
    </div>
    <div *ngIf="data.Equipment.length" class="contentBox">
        <div class="contentHeading" (click)="getDetails('Equipment')">
          <p>No</p>
          <p>Equipment</p>
          <p>Details</p>
        </div>


        <div class="contentSubHeading">Leg 1</div>
<div  *ngFor="let items of data.Equipment.filter(leg1);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div class="contentSubHeading">Leg 2</div>
<div  *ngFor="let items of data.Equipment.filter(leg2);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div class="contentSubHeading">Leg 3</div>
<div  *ngFor="let items of data.Equipment.filter(leg3);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0" [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
</div>


<div class="contentSubHeading">Leg 4</div>
<div  *ngFor="let items of data.Equipment.filter(leg4);let i = index;">
        <div *ngIf="items.Details.filter(emergency).length > 0"  [ngClass]="{'evenRow': 0 !== i % 2, 'oddRow' : 0 === i % 2}">
          <div class="contentRow" (click)="getDetails(items,'Equipment',i)">
            <p>{{i+1}}</p>
            <p>{{ items.Name }}</p>
            <p><span class="monitor">{{ items.Details.filter(monitor).length }}</span></p>
            <p><span class="planned">{{ items.Details.filter(planned).length }}</span></p>
            <p><span class="urgent">{{ items.Details.filter(urgent).length }}</span></p>
            <p><span class="emergency">{{ items.Details.filter(emergency).length }}</span></p>
            <p *ngIf="mode == 'Delete'"><span class="delete"><input type="checkbox" (change)="deleteItems($event,items.Name,'Equipment')"></span></p>
          </div>
        </div>
        </div>

    </div>
  </div>
</div>

