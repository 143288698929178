import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuditFetchService } from '../audit-fetch.service';

@Component({
  selector: 'app-audit-categories',
  templateUrl: './audit-categories.component.html',
  styleUrls: ['./audit-categories.component.css']
})



export class AuditCategoriesComponent implements OnInit {

  @Input() data: Object;
  @Input() selected: String;
  @Input() role: String;

  @Output() detailsEvent = new EventEmitter<any>();

  @Output() addSectionEvent = new EventEmitter<any>();

  @Output() deleteSectionEvent = new EventEmitter<any>();

  mode = null;
  deleteIndex = [];
  ngOnChanges() {
  }

  constructor(public audit:AuditFetchService) {
  }

  ngOnInit(): void {
    this.audit.dataBuild(this.data['General'], this.data['Equipment'])   
  }

  select(event) {
  let chip = event.target;
  this.selected = chip.getAttribute("value");
  }

  monitor(record) {
    return (record.Option === 1 && record.Value != null);
  }
  planned(record) {
    return (record.Option === 2 && record.Value != null);
  }
  urgent(record) {
    return (record.Option === 3 && record.Value != null);
  }
  emergency(record) {
    return (record.Option === 4 && record.Value != null);
  }

  getDetails(items,section = null,sectionIndex = null){
    let elts = items;
    elts['section'] = section;
    elts['sectionIndex'] = sectionIndex;
this.detailsEvent.emit(elts);
  }

leg1(elt){
return elt.Leg == "Leg 1";
}

leg2(elt){
  return elt.Leg == "Leg 2";
  }

  leg3(elt){
    return elt.Leg == "Leg 3";
    }

    leg4(elt){
      return elt.Leg == "Leg 4";
      }

      addSection(){
        this.addSectionEvent.emit();
      }

      deleteItems(event,name, section){
        if(event.target.checked){
          this.deleteIndex.push({section , name});
        }else{
        this.deleteIndex = this.deleteIndex.filter(elt =>{
         return elt.name != name;
        });
        event.target.checked = false;
        }
      }


      deleteSection(){
        if(this.mode == 'Delete'){
          if(this.deleteIndex.length > 0){
            this.deleteSectionEvent.emit(this.deleteIndex);
          }
          this.deleteIndex = [];
        this.mode = null;
      }else{
        this.mode = 'Delete';
      }
      }

}
