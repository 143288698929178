import { Component, OnInit, HostListener } from '@angular/core';
import { UploadService } from './upload.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from "@angular/router";


import * as S3 from 'aws-sdk/clients/s3';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
// s3 = new S3({
//   accessKeyId: "AKIAUJ6YUCUFGLRWQHPY",
//   secretAccessKey: "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",
//   region: "us-east-1"
// });
export class UploadComponent implements OnInit {
  s3 = new S3({
    // accessKeyId: "AKIASGGDYK7Z456YDMV5",
    // secretAccessKey: "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",
    // region: "ap-southeast-1"
    accessKeyId: "AKIAUJ6YUCUFGLRWQHPY",
    secretAccessKey: "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",
    region: "us-east-1"
  });
  uploadList;
  towerName;
  role;
  year;
  currUploadNumber;
  totalNumberOfFiles;
  percentUpload;
  uploadVis = 'visible';
  uploadedHeadVis = 'hidden';
  constructor(private upload: UploadService, private snackBar: MatSnackBar, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    if (this.router.url.search("aero-processing") == 1) {
      this.role = "aero-processing";
    }
    if (this.router.url.search("user") == 1) {
      this.role = 'user';
    }
    if (this.router.url.search("pilot") == 1) {
      this.role = 'pilot';
    }
    if (this.router.url.search("super-admin") == 1) {
      this.role = 'super-admin';
    }
    if (this.router.url.search("telstra-processing") == 1) {
      this.role = 'telstra-processing';
    }
    this.route.params.subscribe(params => {
      this.towerName = params.tower;
      this.year = params.year;
    })


  }


  uploadFolders(files) {
    if (files != null) {
      this.uploadList = files;
      this.currUploadNumber = 0;
      this.percentUpload = 0;
    }
    this.uploadVis = 'hidden';
    this.uploadedHeadVis = 'visible';
    // console.log('this.uploadVis - ', this.uploadVis)
    console.log('this.uploadList - ', this.uploadList)
    this.totalNumberOfFiles = this.uploadList.length

    for (let i = 0; i < this.uploadList.length; i++) {
      let path = this.uploadList[i].webkitRelativePath;
      this.uploadS3(path, this.uploadList[i])
    }
    this.uploadList = []

  }

  // setTimeoutCall(){

  //   console.log('HEEHEE');
  //   this.uploadVis = 'visible';
  //   this.uploadedHeadVis = 'hidden';
  // }

  uploadS3(path, file) {

    console.log('path- ', path);


    let nm_nw = (this.towerName);
    // console.log('this.towerName - ', nm_nw);


    // Key: this.towerName + '/' + path,
    // this.s3.putObject({
    // this.s3.upload({
    this.s3.upload({
      // Bucket: "demo-pilot-upload",
      Bucket: "aerodyne-pwc-telco/towers_data",
      Key: nm_nw + '/' + path,
      Body: file,
    }, (res) => {
      // null if successful
      console.log('res - ', res);
      // RequestTimeTooSkewed: The difference between the request time and the current time is too large.
      // IF THIS ERROR, SHOW MESSAGE
      if (res == null){
        this.currUploadNumber = this.currUploadNumber+1;
        this.percentUpload = Math.round(((this.currUploadNumber/this.totalNumberOfFiles)*100) * 10) / 10
        this.snackBar.open('Uploaded - '+this.percentUpload+'% OR '+this.currUploadNumber+'/'+this.totalNumberOfFiles+' files', "close", { duration: 5000 });

        if (this.currUploadNumber==this.totalNumberOfFiles){
          // console.log('HEEHEE');
          // setTimeout(this.setTimeoutCall, 2000);

          this.uploadVis = 'visible';
          this.uploadedHeadVis = 'hidden';
        }

      }
      else{
        this.uploadVis = 'visible';
        this.uploadedHeadVis = 'hidden';
        this.snackBar.open('Error - '+ res, "close", { duration: 5000 });
        console.log('ERROR - ', res);

      }
    });
  }


  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
  }
  @HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
    event.preventDefault();
  }
  @HostListener("dragend", ["$event"]) onDragEnd(event: any) {
    event.preventDefault();
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      this.getFilesDataTransferItems(event.dataTransfer.items)
    }
  }
  getFilesDataTransferItems(items) {
    for (let item of items) {
      let temp = item.webkitGetAsEntry();
      this.findFile(temp, temp.fullPath)
    }
  }

  findFile(item, dir) {
    if (item.isFile) {
      item.file(file => {
        file.filepath = dir;
        console.log(file)
        this.uploadS3(dir.replace("/", ""), file);
      });
    }
    if (item.isDirectory) {
      let dirReader = item.createReader();
      dirReader.readEntries((entries) => {
        for (let entry of entries) {
          this.findFile(entry, entry.fullPath);
        }
      });
    }
  }


}
