// declare const require: any;
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
// declare let jsPDF;
// declare const jsPDF = require('jspdf');
// require('jspdf-autotable');
// declare import jsPDF from 'jspdf'
// import * as jsPDF from 'jspdf';
// import 'jspdf-autotable'
// import { Observer } from 'rxjs';
// import { Observable } from 'rxjs';
import * as fs from 'fs';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import autoTable from 'jspdf-autotable'
import { Observer } from 'rxjs';
import { Observable } from 'rxjs';
// import base64url from "base64url";

import { AuditFetchService } from '../audit-fetch.service';
import { ExcelService } from '../excel.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogPdfComponent } from '../dialog-pdf/dialog-pdf.component';
// import autoTable from 'jspdf-autotable'; 
// import imageToBase64 from 'image-to-base64/browser';

// let XLSX = require('xlsx');
// var workbook = XLSX.readFile('test.xlsx');

let doc = new jsPDF('p', 'mm', 'a4')
// const doc = new jsPDF('p', 'pt', 'letter');
    
var width = doc.internal.pageSize.getWidth();
var height = doc.internal.pageSize.getHeight();

export var printObjStatus = 'Printing Now ... ';
export var printObjSubStatus = '';
// declare const require: any;
// const jsPDF = require('jspdf');
// require('jspdf-autotable');
@Component({
  selector: 'app-audit-details',
  templateUrl: './audit-details.component.html',
  styleUrls: ['./audit-details.component.css']
})



export class AuditDetailsComponent implements OnInit, OnChanges {



  @Input() data: Object;

  @Input() item: Object;

  @Input() role: String;
  deleteIndex = [];
  mode = null;
  tbData = {
    head: [['Name', 'Email', 'Country']],
    body: [
      ['David', 'david@example.com', 'Sweden'],
      ['Castille', 'castille@example.com', 'Spain']
    ],
  }
  tbData1;
  tbData3 = [];
  tb = [];
  tb1 = [];
  tb2 = [];
  tbh = [];
  imglink;
  base64Image;
  imgObj;
  reportFindingData = [];

  cancelledPrint = false;
  dialogRef;

  @Output() imageEvent = new EventEmitter<any>();


  @Output() editEvent = new EventEmitter<any>();


  @Output() addDeleteEvent = new EventEmitter<any>();

  selected: String;

  ngOnChanges() {
    if (this.item == 'General') {
      this.selected = "General";
      return;
    }
    if (this.item == 'Equipment') {
      this.selected = "Equipment";
      return;
    }
    this.selected = "Details";
    return;
  }


  constructor(private audit: AuditFetchService, private excel: ExcelService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.selected = "General";

    // for testing you can add any image url here or get dynamically  from other methods as you require
    //  let imageUrl = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.BAL.BURWASH/CV/DJI_0839.JPG';

    //  this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {    
    //   //  console.log(base64data);
    //    // this is the image as dataUrl
    //    this.base64Image = 'data:image/jpg;base64,' + base64data;
    //  });


  }

  selectDetails(event) {
    let chip = event.target;
    this.selected = chip.getAttribute("value");
  }

  mmc(item) {
    return item.Option !== 1;
  }

  sendImage(src: String) {
    this.imageEvent.emit(src);
  }


  editData(key, value, section, detailIndex, sectionIndex, bool, sectionName = null) {
    this.editEvent.emit({ key, value, section, detailIndex, sectionIndex, bool, sectionName });
  }

  addSection() {
    this.addDeleteEvent.emit({ mode: 'Add', add: { sectionIndex: this.item['sectionIndex'], section: this.item['section'] } });
  }

  deleteItems(event, name, section, detailIndex, sectionIndex) {
    if (event.target.checked) {
      this.deleteIndex.push({ name, detailIndex, section, sectionIndex });
    }
    else {
      this.deleteIndex = this.deleteIndex.filter(elt => {
        return (elt.name != name && elt.detailIndex != detailIndex && elt.sectionIndex == sectionIndex || (elt.detailIndex == detailIndex && elt.sectionIndex != sectionIndex));//
      });
    }
  }


  deleteSection() {
    if (this.mode == 'Delete') {
      if (this.deleteIndex.length > 0) {
        this.addDeleteEvent.emit({ delete: this.deleteIndex, mode: 'Delete' });
        //  console.log(this.deleteIndex);

      }
      this.deleteIndex = [];
      this.mode = null;
    } else {
      this.mode = 'Delete';
      this.deleteIndex = [];
    }
  }

  async getJSON() {
    console.log('JSON:');
    let generalDataList = this.audit.audit_data[0];
    let equipmentDataList = this.audit.audit_data[1];
    console.log('generalDataList:', generalDataList);
    console.log('equipmentDataList:', equipmentDataList);

    let fullJsonOutput = {}
    let overallGenDetailsOutput = []
    let overallEquipDetailsOutput = [];

    for (let index = 0; index < generalDataList.length; index++) {
      let generalDataListItm = generalDataList[index];
      let generalDataListItmDetails = generalDataList[index].Details;
      // console.log('generalDataListItm - ', generalDataListItm);
  
  
      let tmpGenItemObj = {}
      tmpGenItemObj['Section'] = generalDataListItm.Name;
      tmpGenItemObj['Details'] = [];
  
      for (let index2 = 0; index2 < generalDataListItmDetails.length; index2++) {
        if (generalDataListItmDetails[index2].Name == "Latitude, Longitude") {
          let latLongTxt = (generalDataListItmDetails[index2].Value).split(' ')
          let tmpIndiItem = {}
          tmpIndiItem['Item'] = "Latitude";
          tmpIndiItem['Description'] = latLongTxt[0];
          // tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          tmpGenItemObj['Details'].push(tmpIndiItem)
  
          tmpIndiItem = {}
          tmpIndiItem['Item'] = "Longitude";
          tmpIndiItem['Description'] = latLongTxt[1];
          // tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          tmpGenItemObj['Details'].push(tmpIndiItem)
  
        }
        else {
          let tmpIndiItem = {}
          // console.log('index - ', index);
          // console.log('index2 - ', generalDataListItmDetails[index2].Value);
          let descVal = (generalDataListItmDetails[index2].Value)
          // descVal = descVal.replaceAll("(ft)","")
          // descVal = descVal.replaceAll("ft","")
          // descVal = descVal.replaceAll("()","")
          if (descVal != '' && descVal !== 'null' && descVal !== null) {
            descVal = descVal.replace(/(ft)/g, "");
            descVal = descVal.replace(/( ft )/g, "");
            descVal = descVal.replace(/ft/g, "");
            descVal = descVal.replace(/()/g, "");
            if (descVal.replace(/ /g, "") == '') {
              descVal = 'N/A';
            }
          }
  
          tmpIndiItem['Item'] = generalDataListItmDetails[index2].Name;
          tmpIndiItem['Description'] = descVal;
          if(generalDataListItm.Name!="INSPECTION DETAILS" && generalDataListItm.Name!="SITE INFORMATION" && generalDataListItm.Name!="STRUCTURE DETAILS" && generalDataListItm.Name!="PAINT" && generalDataListItm.Name!="AVIATION / OBSTRUCTION LIGHTING" && generalDataListItm.Name!="COMPOUND DETAILS"){
            tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          }
          if(generalDataListItm.Name=="PAINT" && generalDataListItmDetails[index2].Name =="Paint / Galvanic Coating condition"){
            tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          }
          if(generalDataListItm.Name=="AVIATION / OBSTRUCTION LIGHTING" && generalDataListItmDetails[index2].Name =="Comments"){
            tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          }
          if(generalDataListItm.Name=="COMPOUND DETAILS" && generalDataListItmDetails[index2].Name =="Compound fence condition"){
            tmpIndiItem['Severity'] = generalDataListItmDetails[index2].Option;
          }
          tmpGenItemObj['Details'].push(tmpIndiItem)
        }
      }
      if(generalDataListItm.Name!="SITE IMAGES"){
        overallGenDetailsOutput.push(tmpGenItemObj);
      }
    }

    fullJsonOutput['GeneralTableData'] = overallGenDetailsOutput;

    if (equipmentDataList.length > 0) {
      for (let index3 = 0; index3 < equipmentDataList.length; index3++) {
        let equipDataListItmDetails = equipmentDataList[index3].Details;
  
        let tmpEquipItemObj = {}
        tmpEquipItemObj['EquipmentReference'] = equipmentDataList[index3].Name;
        tmpEquipItemObj['Details'] = [];
  
  
        for (let index4 = 0; index4 < equipDataListItmDetails.length; index4++) {
          let tmpIndiItem = {}
          let descVal = equipDataListItmDetails[index4].Value
          let antSizeVal = (equipDataListItmDetails[index4].Name.toLowerCase())
  
  
          if ((antSizeVal.includes("antenna size")) || (antSizeVal.includes("dimension"))) {
            let equipType = ((equipDataListItmDetails[index4 - 1].Value).toLowerCase()).replace(" ", "");
            // console.log('equipType - ', equipType);
            if (equipType.includes("panel")) {
              // descVal = descVal.replaceAll("(ft)","")
              // descVal = descVal.replaceAll("ft","")
              // descVal = descVal.replaceAll("()","")
  
              if (descVal != '') {
                descVal = descVal.replace(/(ft)/g, "");
                descVal = descVal.replace(/( ft )/g, "");
                descVal = descVal.replace(/ft/g, "");
                descVal = descVal.replace(/()/g, "");
                if (descVal.replace(/ /g, "") == '') {
                  descVal = 'N/A';
                }
              }
              let descValSplit = (descVal.toLowerCase()).split("x")
  
              tmpIndiItem['Item'] = "Antenna Size Height (Panel , Dipole) or Diameter (Dish) (ft)";
              tmpIndiItem['Description'] = descValSplit[0];
              // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
              tmpEquipItemObj['Details'].push(tmpIndiItem)
  
              tmpIndiItem = {}
              tmpIndiItem['Item'] = "Antenna Size Width (Panel only) (ft)";
              tmpIndiItem['Description'] = descValSplit[1];
              // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
              tmpEquipItemObj['Details'].push(tmpIndiItem)
  
              tmpIndiItem = {}
              tmpIndiItem['Item'] = "Antenna Size Depth (Panel only) (ft)";
              tmpIndiItem['Description'] = descValSplit[2];
              // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
              tmpEquipItemObj['Details'].push(tmpIndiItem)
            }
            else {
              tmpIndiItem['Item'] = "Antenna Size Height (Panel , Dipole) or Diameter (Dish) (ft)";
              // descVal = descVal.replaceAll("(ft)","")
              // descVal = descVal.replaceAll("ft","")
              // descVal = descVal.replaceAll("()","")
  
              if (descVal != '') {
                descVal = descVal.replace(/(ft)/g, "");
                descVal = descVal.replace(/( ft )/g, "");
                descVal = descVal.replace(/ft/g, "");
                descVal = descVal.replace(/()/g, "");
                if (descVal.replace(/ /g, "") == '') {
                  descVal = 'N/A';
                }
              }
  
              tmpIndiItem['Description'] = descVal;
              // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
              tmpEquipItemObj['Details'].push(tmpIndiItem)
            }
  
          }
          else if (antSizeVal.includes("azimuth")) {
            tmpIndiItem['Item'] = "Azimuth (deg)";
            // descVal = descVal.replaceAll("(ft)","")
            // descVal = descVal.replaceAll("ft","")
            // descVal = descVal.replaceAll("(deg)","")
            // descVal = descVal.replaceAll("deg","")
            // descVal = descVal.replaceAll("()","")
  
            if (descVal != '') {
              descVal = descVal.replace(/(ft)/g, "");
              descVal = descVal.replace(/( ft )/g, "");
              descVal = descVal.replace(/ft/g, "");
              descVal = descVal.replace(/(deg)/g, "");
              descVal = descVal.replace(/( deg )/g, "");
              descVal = descVal.replace(/deg/g, "");
              descVal = descVal.replace(/()/g, "");
              if (descVal.replace(/ /g, "") == '') {
                descVal = 'N/A';
              }
            }
  
            tmpIndiItem['Description'] = descVal;
            // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
            tmpEquipItemObj['Details'].push(tmpIndiItem)
          }
          else if (antSizeVal.includes("from ground")) {
            tmpIndiItem['Item'] = "Height from ground (ft)";
            // descVal = descVal.replaceAll("(ft)","")
            // descVal = descVal.replaceAll("ft","")
            // descVal = descVal.replaceAll("()","")
  
            if (descVal != '') {
              descVal = descVal.replace(/(ft)/g, "");
              descVal = descVal.replace(/( ft )/g, "");
              descVal = descVal.replace(/ft/g, "");
              descVal = descVal.replace(/()/g, "");
              if (descVal.replace(/ /g, "") == '') {
                descVal = 'N/A';
              }
            }
            tmpIndiItem['Description'] = descVal;
            // tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
            tmpEquipItemObj['Details'].push(tmpIndiItem)
          }
          else {
            tmpIndiItem['Item'] = equipDataListItmDetails[index4].Name;
            // descVal = descVal.replaceAll("(ft)","")
            // descVal = descVal.replaceAll("ft","")
            // descVal = descVal.replaceAll("()","")
  
            if (descVal != '') {
              descVal = descVal.replace(/(ft)/g, "");
              descVal = descVal.replace(/( ft )/g, "");
              descVal = descVal.replace(/ft/g, "");
              descVal = descVal.replace(/()/g, "");
              if (descVal.replace(/ /g, "") == '') {
                descVal = 'N/A';
              }
            }
            tmpIndiItem['Description'] = descVal;
            if(equipDataListItmDetails[index4].Name=="Equipment damage visible?" || equipDataListItmDetails[index4].Name=="Feeder damage visible?" ){
              tmpIndiItem['Severity'] = equipDataListItmDetails[index4].Option;
            }
            tmpEquipItemObj['Details'].push(tmpIndiItem)
          }
        }
  
        overallEquipDetailsOutput.push(tmpEquipItemObj)
      }
  
    }

    fullJsonOutput['EquipmentTableData'] = overallEquipDetailsOutput

    console.log('fullJsonOutput:', fullJsonOutput);

    let downloadFileNm = generalDataList[1].Details[0].Value+'_json_data'+'.json'
    download(downloadFileNm, JSON.stringify(fullJsonOutput))
  }

  async getExcel(){
    this.excel.generateExcel(this.audit.audit_data);
  }

  async buildPDF() {
    this.openDialog();
    this.cancelledPrint = false;
    console.log('Starting pdf creation ... ');
    
    // Refresh vars
    doc = new jsPDF('p', 'mm', 'a4')
    width = doc.internal.pageSize.getWidth();
    height = doc.internal.pageSize.getHeight();

    
    // FIX ROW HEIGHT FOR ALL

    if( this.cancelledPrint == false){
      // // Page 1 - Add CoverPage
      console.log('Adding Cover Page ... ');
      printObjStatus = 'Adding Cover Page ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      await this.addCoverPage();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Table of Content ... ';
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      doc.setTextColor('#000000')
      this.getAllReportFindings();
      // // Page 2 - Add Table of Content
      console.log('Adding Table of Content ... ');
      this.addTableOfContentPage();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Site Details ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Page 3 - Add Site Details
      console.log('Adding Site Details ... ');
      this.addSiteDetailsPage();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Risk Assessment ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Page 4 - Add Risk Assessment
      console.log('Adding Risk Assessment ... ');
      this.addRiskAssessmentPage();
    }


    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Report Finding Summary ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Add Emergency, Urgent, Planned - as per list
      // REPORT FINDING SUMMARY
      console.log('Adding Report Finding Summary ... ');
      await this.addReportFindingPage();
      doc.setTextColor(0,0,0);
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Checklist ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Checklist - Print all tables // Color change of Emergency, Urgent, Planned // Heading repeat in each page
      console.log('Adding Checklist ... ');
      this.addCheckListTables();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Antenna Tables ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Print Antenna table
      console.log('Adding Antenna Tables ... ');
      this.parseaddAntennaTables();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Adding Site Pictures ... '
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      // Antenna data if list is there
      // doc.internal.scaleFactor = 1.33;
      // Add Site Pictures
      console.log('Adding Site Pictures ... ');
      await this.addSitePictures();
    }

    if( this.cancelledPrint == false){
      printObjStatus = 'Printing PDF ... '
      let towerName = this.audit.audit_data[0][1].Details[0].Value;
      printObjSubStatus = '';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      console.log('Printing pdf - ', towerName)
      await doc.save('AuditReport_'+towerName+'.pdf')
      this.dialogRef.close();
    }

  }

  openDialog(){
    this.dialogRef = this.dialog.open(DialogPdfComponent, {
      width: '500px',
      data: {name: printObjStatus, animal: printObjSubStatus}
    });

    this.dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.closeDialogTrigger();
      // this.animal = result;
    });
  }
  
  closeDialogTrigger(){
    console.log('Dialog close Function');
    this.cancelledPrint = true;
    doc = new jsPDF('p', 'mm', 'a4')
    width = doc.internal.pageSize.getWidth();
    height = doc.internal.pageSize.getHeight();
  }

  async addCoverPage() {
    
    printObjSubStatus = 'Adding cover';
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    var img1 = this.audit.audit_data[0][1].Details[0].Image[0];
    // console.log('link - ', this.audit.audit_data[0][1].Details[0].Image[0])


    // var img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.BAL.BURWASHb/FTV/DJI_0109.JPG'
    // var img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ALB.GEIKIE/FTV/DJI_0002.JPG'
    // var img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/HQ%20Processing/DM/TWR.KIN.COBOURG.26458/Defect%20Marking/5.%20Structure%20Summary/DJI_0113.JPG'
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    let compressed = String(await compressImageCover(resNw));
    doc.addImage(compressed, 'JPEG', 0, 0, width, height);

    doc.setTextColor('#ffffff');
    doc.setFontSize(35);
    doc.text('TELCO TOWER INSPECTION', 15, 50); //x,y
    doc.setFontSize(20);
    doc.text(''+this.audit.audit_data[0][1].Details[0].Value, 15, 70); //x,y

    var img2 = 'assets/images/icons/iconReportPwC.png'
    doc.addImage(img2, 'PNG', 130, 10, 53, 20);
    var img3 = 'assets/images/icons/pwcTransparent.png'
    doc.addImage(img3, 'PNG', 15, 10, 26, 20);
    
    doc.setFontSize(20);
    doc.text('Prepared by:', 155, height - 40); //x,y
    doc.setFontSize(27);
    doc.text('Aerodyne', 155, height - 28); //x,y
  }

  addTableOfContentPage() {


    doc.addPage();

    doc.setFontSize(16);
    // doc.text('TABLE OF CONTENTS', 45, 40); //x,y
    doc.text('TABLE OF CONTENTS', width/2, 40, { align: 'center' });
      

    let bodyArr = [
      ['1', 'Site Details', '2'],
      ['2', 'Risk Assessment', '3'],
      ['3', 'Report Finding Summary', '4']
    ]

    let baseReportFindingPages = 1
    let baseReportIndex = 1
    if (this.reportFindingData.length>0){
      baseReportFindingPages = parseInt(String((parseInt(String(this.reportFindingData.length))-1)/4))
      baseReportFindingPages = baseReportFindingPages + 1

      // bodyArr.push(['3', 'Report Finding Summary', '4'])
      baseReportIndex = 1
    }
    console.log('baseReportFindingPages - ', baseReportFindingPages);
    
    let checklistIndex = 3+baseReportIndex;
    let checklistPageNo = 4+baseReportFindingPages;
    let antennaIndex = 4+baseReportIndex;
    let antennaPageNo = 8+baseReportFindingPages;
    let sitePicsIndex = 5+baseReportIndex;
    let sitePicsPageNo = 9+baseReportFindingPages;

    bodyArr.push(
      [String(checklistIndex), 'Checklist', String(checklistPageNo)],
      [String(antennaIndex), 'Antenna Data', String(antennaPageNo)],
      [String(sitePicsIndex), 'Site Pictures', String(sitePicsPageNo)]
    )
    // bodyArr.push(
    //   [String(checklistIndex), 'Checklist', String(checklistPageNo)],
    //   [String(sitePicsIndex), 'Site Pictures', String(sitePicsPageNo)]
    // )


    autoTable(doc, {
      head: [['Item', 'Description', 'Page Number']],
      body: bodyArr,
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 100 },
        2: { cellWidth: 60 }
      },
      theme: 'grid',
      styles: {
        fontSize: 12,
        minCellHeight: 12
      },
      margin: { top: 55 },
      didParseCell(data) {
        if(data.section==='head') {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = '#257700';
        }
      },
    });

  }

  addSiteDetailsPage(){
    doc.addPage();

    // doc.setFontSize(30);
    // doc.text('SITE DETAILS', 69, 40); //x,y
    doc.setFontSize(16);
    doc.text('SITE DETAILS', width/2, 40, { align: 'center' });

    let generalDataList = this.audit.audit_data[0];
    let siteDetailsBody = [];

    let siteInfoList = generalDataList[1].Details;
    for (let index = 0; index < siteInfoList.length; index++) {
      let tmpArr = [];
      tmpArr.push(siteInfoList[index].Name)

      let tmpVal = (siteInfoList[index].Value);
      if(tmpVal!= '' && tmpVal!= 'null' && tmpVal!= null ){
        tmpVal = (tmpVal).replace('(ft)', '')
        tmpVal = (tmpVal).replace('( ft )', '')
        tmpVal = (tmpVal).replace('ft', '')
        tmpVal = (tmpVal).replace('()', '')
      }
      tmpArr.push(tmpVal)
      
      siteDetailsBody.push(tmpArr);
    }

    let structureDetailsList = generalDataList[2].Details
    // console.log('structureDetailsList- ', structureDetailsList);
    for (let index = 0; index < 5; index++) {
      let tmpArr = [];
      tmpArr.push(structureDetailsList[index].Name)
      
      let tmpVal = (structureDetailsList[index].Value);
      if(tmpVal!= '' && tmpVal!= 'null' && tmpVal!= null ){
        tmpVal = (tmpVal).replace('(ft)', '')
        tmpVal = (tmpVal).replace('( ft )', '')
        tmpVal = (tmpVal).replace('ft', '')
        tmpVal = (tmpVal).replace('()', '')
      }
      tmpArr.push(tmpVal);
      
      siteDetailsBody.push(tmpArr);
    }

    // siteDetailsBody.push([
    //   generalDataList[9].Details[1].Name,
    //   generalDataList[9].Details[1].Value
    // ]);
    siteDetailsBody.push([
      "Height of Aviation Lights (ft)",
      generalDataList[9].Details[1].Value
    ]);

    // console.log('siteDetailsBody - ', siteDetailsBody);
    
    autoTable(doc, {
      head: [['Item', 'Description']],
      body: siteDetailsBody,
      columnStyles: {
        0: { cellWidth: 80 },
        1: { cellWidth: 100 }
      },
      theme: 'grid',
      styles: {
        fontSize: 12,
        minCellHeight: 12
      },
      margin: { top: 55 },
      didParseCell(data) {
        if(data.section==='body') {
          // #257700
            if(data.column.index ===0) {
              data.cell.styles.fontStyle = 'bold';
            }
            // data.cell.styles.textColor = '#000000';
        }
        if(data.section==='head') {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = '#257700';
        }
      },
    });
    
  }

  addRiskAssessmentPage(){
    doc.addPage();

    // doc.setFontSize(30);
    // doc.text('RISK ASSESSMENT', 56, 40); //x,y'SITE DETAILS'
    doc.setFontSize(16);
    doc.text('RISK ASSESSMENT', width/2, 40, { align: 'center' });
    
    autoTable(doc, {
      head: [['Item', 'Severity', 'Description', 'Examples']],
      body: [
        ["1", "Monitor", "For inspection items that are fully functional and in good condition", ""],
        ["2", "Planned", "For minor severity or damage observed on inspection items, which should be paid attention to in the near future, which might not affect the functionality of the structure at the time of inspection", "Bend members, stagnant water, shrubs, foreign/unused objects, mold, low level rust, faded/peeled paint, faded/missing signage, cracked base, damaged/missing lighting arrestor, damaged/missing aviation light/bulb, untidy/damaged cables, minor damaged ground items"],
        ["3", "Urgent", "For major severity or damage observed on inspection items, which should be paid attention to as soon as possible, which might affect the functionality of the structure at the time of inspection", "Missing member/bolt/nut at tower legs, ground movement/cracked base, unusual installation of structure, platform damage, damage on access ladder, loose guy wires, missing guy wire fasteners"],
        ["4", "Emergency", "For critical severity or damage observed on inspection items, which should be paid attention urgently, which might not just affect the functionality of the tower, but the well-being of the whole structure itself as well as the danger it poses to its surroundings, at the time of inspection", "Slanted tower structure, cracked tower structure, broken guy wires, unstable anchor head positions, severe ground movement/cracked base"]
      ],
      columnStyles: {
        0: {cellWidth: 15},
        1: {cellWidth: 35},
        2: {cellWidth: 65},
        3: {cellWidth: 65}
      },
      styles: {
        fontSize: 12
      },
      margin: { top: 55 },
      didParseCell(data) {
        if(data.section==='body') {
          // #257700
            if(data.row.index===0 && data.column.index ===1) {
              data.cell.styles.fontStyle = 'bold';
              // #5A874C , 
              data.cell.styles.fillColor = '#3DA501';
            }
            if(data.row.index===1 && data.column.index ===1) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#FBCC66';
            }
            if(data.row.index===2 && data.column.index ===1) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#E1693C';
            }
            if(data.row.index===3 && data.column.index ===1) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#B3394F';
            }
            // if(data.row.index===3 && data.column.index ===2) {
            //   data.cell.text = ['bold', '<b>', 'bold', '</b>'];
            // }
            data.cell.styles.textColor = '#000000';
        }
        if(data.section==='head') {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = '#257700';
        }
      },
      theme: 'grid'
    });
    
  }

  async getAllReportFindings(){
    this.reportFindingData = [];
    let generalDataList = this.audit.audit_data[0];

    for (let index = 0; index < generalDataList.length; index++) {
      let rowFullData = generalDataList[index].Details;
      // console.log('rowFullData - ', rowFullData);
      
      // let rowBodyData = []
      for (let index2 = 0; index2 < rowFullData.length; index2++) {
        if (rowFullData[index2].Option != 1){
          this.reportFindingData.push(rowFullData[index2])
        }
      }
    }
    // console.log('reportFindingData - ', this.reportFindingData);
  }

  async addReportFindingPage(){

    doc.addPage();
    // doc.setFontSize(16);
    // doc.text('REPORT FINDING SUMMARY', 71, 21); //x,y
    doc.setFontSize(16);
    doc.text('REPORT FINDING SUMMARY', width/2, 21, { align: 'center' });
    // doc.text('LINE OF SIGHT PICTURES', 78, 12); //x,y

    // await this.add4SiteViews00(reportFindingData, reportFindingData[0].Name+': '+reportFindingData[0].Value, reportFindingData[1].Name+': '+reportFindingData[1].Value, reportFindingData[2].Name+': '+reportFindingData[2].Value)
    for (let index = 0; index < this.reportFindingData.length; index=index+4) {
      let numElmLeft = this.reportFindingData.length - index
      console.log('numElmLeft - ', numElmLeft);
      if(numElmLeft>4){
        numElmLeft = 4;
      }
      if (index != 0 ){
        doc.addPage();
      }
      await this.add4ReportFindingImages(this.reportFindingData, index, numElmLeft)
    }
    
  }

  async add4ReportFindingImages(sitePicturesArr, currIndex, toPrint){

    let multiFactorWidth = 0.4;
    let divisionFactorHeight = 3.5;
    let startX = 16;
    let startY = 35;
    let diffY = 43;
    let diffX = 15;
    let resizeDivision = 3;
    doc.setFontSize(12);
    
    console.log('currIndex - ', currIndex);
    // console.log('sitePicturesArr - ', sitePicturesArr);
    
    console.log('Adding ReportFinding - ', (currIndex+1));
    printObjSubStatus = 'Adding ReportFinding - '+(currIndex+1);
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    var img1 = sitePicturesArr[currIndex].Image[0];
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    // console.log('resNw - ', resNw);
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);

    let addText = sitePicturesArr[currIndex].Value
    let addTextName = sitePicturesArr[currIndex].Name
    let strArr =  []
    if (addText.includes('Recommendations:')){
      strArr = addText.split('Recommendations:')
      let preStr = 'Severity 2 - Planned: ';
      if(sitePicturesArr[currIndex].Option == 1){
         doc.setTextColor(255,0,0);
      }
      if(sitePicturesArr[currIndex].Option == 2){
         doc.setTextColor(181,145,72);
        //  doc.setTextColor(255,204,102);
      }
      if(sitePicturesArr[currIndex].Option == 3){
         doc.setTextColor(255,105,60);
         preStr = 'Severity 3 - Urgent: ';
      }
      if(sitePicturesArr[currIndex].Option == 4){
         doc.setTextColor(179,57,79);
         preStr = 'Severity 4 - Emergency: ';
      }
      let firstStr = preStr + addTextName+': '+ String(strArr[0]);
      var splitTitle = doc.splitTextToSize(firstStr, 80);
      doc.text(splitTitle, startX, 127);

      let secondStr = 'Recommendations: '+ String(strArr[1]);
      let splitTitle1 = doc.splitTextToSize(secondStr, 80);

      // doc.setFillColor(255,255,200);
      // doc.rect(100, 20, 10, 10, 'F');

      doc.setTextColor(0,0,0);
      doc.text(splitTitle1, startX, 142);
    }
    else{
      let preStr = 'Severity 2 - Planned: ';
      if(sitePicturesArr[currIndex].Option == 1){
         doc.setTextColor(255,0,0);
      }
      if(sitePicturesArr[currIndex].Option == 2){
         doc.setTextColor(181,145,72);
        //  doc.setTextColor(255,204,102);
      }
      if(sitePicturesArr[currIndex].Option == 3){
         doc.setTextColor(255,105,60);
         preStr = 'Severity 3 - Urgent: ';
      }
      if(sitePicturesArr[currIndex].Option == 4){
         doc.setTextColor(179,57,79);
         preStr = 'Severity 4 - Emergency: ';
      }
      let firstStr = preStr+addTextName+': '+ String(addText);
      var splitTitle = doc.splitTextToSize(firstStr, 80);
      doc.text(splitTitle, startX, 127);
    }

    //  doc.setTextColor(255,0,0);
    // And then change back to normal black.

    if (toPrint> 1){
      console.log('Adding ReportFinding - ', (currIndex+2));
      printObjSubStatus = 'Adding ReportFinding - '+(currIndex+2);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};

      img1 = sitePicturesArr[currIndex+1].Image[0];
      resNw = String(await getBase64ImageFromUrl000123(img1));
      // console.log('resNw - ', resNw);
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      doc.addImage(compressed, 'JPEG', startX + diffX + (width*multiFactorWidth), startY, width*multiFactorWidth, height/divisionFactorHeight);

      // doc.text(sitePicturesArr[currIndex+1].Value, startX + diffX + (width*multiFactorWidth), 140);
      let addText = sitePicturesArr[currIndex+1].Value
      let addTextName = sitePicturesArr[currIndex+1].Name
      let strArr =  []
      if (addText.includes('Recommendations:')){
        strArr = addText.split('Recommendations:')

        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+1].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+1].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+1].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+1].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }
        let firstStr = preStr + addTextName+': '+ String(strArr[0]);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX + diffX + (width*multiFactorWidth), 127);

        
        let secondStr = 'Recommendations: '+ String(strArr[1]);
        let splitTitle1 = doc.splitTextToSize(secondStr, 80);
        doc.setTextColor(0,0,0);
        doc.text(splitTitle1, startX + diffX + (width*multiFactorWidth), 142);

        // doc.text('Recommendations: '+splitTitle, startX + diffX + (width*multiFactorWidth), 145);
      }else{
        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+1].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+1].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+1].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+1].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }
        let firstStr = preStr+addTextName+': '+ String(addText);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX + diffX + (width*multiFactorWidth), 127);
      }
    }
    

    if (toPrint> 2){
      console.log('Adding ReportFinding - ', (currIndex+3));
      printObjSubStatus = 'Adding ReportFinding - '+(currIndex+3);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};

      img1 = sitePicturesArr[currIndex+2].Image[0];
      resNw = String(await getBase64ImageFromUrl000123(img1));
      // console.log('resNw - ', resNw);
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      doc.addImage(compressed, 'JPEG', startX, startY+(diffY)+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);

      // doc.text(sitePicturesArr[currIndex+2].Value, startX, 344);
      let addText = sitePicturesArr[currIndex+2].Value
      let addTextName = sitePicturesArr[currIndex+2].Name
      let strArr =  []
      if (addText.includes('Recommendations:')){
        strArr = addText.split('Recommendations:')
        
        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+2].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+2].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+2].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+2].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }

        let firstStr = preStr + addTextName+': '+ String(strArr[0]);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX, 255);

        

        let secondStr = 'Recommendations: '+ String(strArr[1]);
        let splitTitle1 = doc.splitTextToSize(secondStr, 80);
        doc.setTextColor(0,0,0);
        doc.text(splitTitle1, startX, 270);

        // doc.text('Recommendations: '+splitTitle, startX, 235);
      }else{
        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+2].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+2].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+2].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+2].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }
        let firstStr = preStr+addTextName+': '+ String(addText);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX, 255);
      }
    }


    if (toPrint> 3){
      console.log('Adding ReportFinding - ', (currIndex+4));
      printObjSubStatus = 'Adding ReportFinding - '+(currIndex+4);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};

      img1 = sitePicturesArr[currIndex+3].Image[0];
      resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      doc.addImage(compressed, 'JPEG', startX + diffX + (width*multiFactorWidth), startY+(diffY)+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);

      // doc.text(sitePicturesArr[currIndex+3].Value, startX + diffX + (width*multiFactorWidth), 355);
      let addText = sitePicturesArr[currIndex+3].Value
      let addTextName = sitePicturesArr[currIndex+3].Name
      let strArr =  []
      if (addText.includes('Recommendations:')){
        strArr = addText.split('Recommendations:')

        // var splitTitle = doc.splitTextToSize(strArr[0], 80);
        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+3].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+3].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+3].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+3].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }
        let firstStr = preStr + addTextName+': '+ String(strArr[0]);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX + diffX + (width*multiFactorWidth), 255);

        
        let secondStr = 'Recommendations: '+ String(strArr[1]);
        let splitTitle1 = doc.splitTextToSize(secondStr, 80);
        doc.setTextColor(0,0,0);
        doc.text(splitTitle1, startX + diffX + (width*multiFactorWidth), 270);

        // doc.text('Recommendations: '+splitTitle, startX + diffX + (width*multiFactorWidth), 145);
      }else{
        let preStr = 'Severity 2 - Planned: ';
        if(sitePicturesArr[currIndex+3].Option == 1){
           doc.setTextColor(255,0,0);
        }
        if(sitePicturesArr[currIndex+3].Option == 2){
           doc.setTextColor(181,145,72);
          //  doc.setTextColor(255,204,102);
        }
        if(sitePicturesArr[currIndex+3].Option == 3){
           doc.setTextColor(255,105,60);
           preStr = 'Severity 3 - Urgent: ';
        }
        if(sitePicturesArr[currIndex+3].Option == 4){
           doc.setTextColor(179,57,79);
           preStr = 'Severity 4 - Emergency: ';
        }
        let firstStr = preStr+addTextName+': '+ String(addText);
        var splitTitle = doc.splitTextToSize(firstStr, 80);
        doc.text(splitTitle, startX + diffX + (width*multiFactorWidth), 255);
      }

    }
  }

  addCheckListTables(){
    doc.addPage();

    // doc.setFontSize(30);
    // doc.text('CHECKLISTS', 70, 40); //x,yREPORT FINDING SUMMARY
    doc.setFontSize(16);
    doc.text('CHECKLISTS', width/2, 40, { align: 'center' });

    // ALL FROM STRUCTURE DETAILS
    let generalDataList = this.audit.audit_data[0];

    for (let index = 2; index < 12; index++) {
      let rowFullData = generalDataList[index].Details;

      let rowBodyData = []
      let severityIndexArr = [];
      for (let index2 = 0; index2 < rowFullData.length; index2++) {
        let tmpArr = [];
        tmpArr.push(String(index2+1))
        tmpArr.push(rowFullData[index2].Name)
        // tmpArr.push(rowFullData[index2].Value)
        // console.log('rowFullData[index2].Value - ', rowFullData[index2].Value)
        let tmpVal = (rowFullData[index2].Value)

        if(tmpVal!= '' && tmpVal!= 'null' && tmpVal!= null ){
          tmpVal.replace('(ft)', '')
          tmpVal = (tmpVal).replace('( ft )', '')
          tmpVal = (tmpVal).replace('ft', '')
          tmpVal = (tmpVal).replace('()', '')
        }
        tmpArr.push(tmpVal)

        if(rowFullData[index2].Option!=1){
          let tmpObjNw = {}
          tmpObjNw['Severity'] = rowFullData[index2].Option;
          tmpObjNw['index'] = index2;
          severityIndexArr.push(tmpObjNw)
        }

        rowBodyData.push(tmpArr);
      }
      
      // console.log('severityIndexArr - ', severityIndexArr);
      
      let minHeight = 55;
      if(index > 2){
        minHeight = 22;
      }

      autoTable(doc, {
        head: [
          [
            {content: generalDataList[index].Name, colSpan: 3, styles: {fontSize: 14, halign: 'center', fillColor:'#69a648'}}
          ],
          ['No.', 'Item', 'Description']
        ],
        body: rowBodyData,
        columnStyles: {
          0: { cellWidth: 13 },
          1: { cellWidth: 85 },
          2: { cellWidth: 87 }
        },
        theme: 'grid',
        styles: {
          fontSize: 12,
          minCellHeight: 12
        },
        margin: { top: minHeight },
        didParseCell(data) {
          if(data.section==='body') {
            // #257700
              if(data.column.index ===1) {
                data.cell.styles.fontStyle = 'bold';
              }
              for(let indx = 0; indx < severityIndexArr.length; indx++){
                if(data.row.index === severityIndexArr[indx].index && data.column.index ===2) {
                  if(severityIndexArr[indx].Severity==2){
                    // data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = '#FBCC66';
                    data.cell.styles.textColor = '#000000';
                  }
                  if(severityIndexArr[indx].Severity==3){
                    // data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = '#E1693C';
                    data.cell.styles.textColor = '#000000';
                  }
                  if(severityIndexArr[indx].Severity==4){
                    // data.cell.styles.fontStyle = 'bold';
                    data.cell.styles.fillColor = '#B3394F';
                    data.cell.styles.textColor = '#000000';
                  }
                }
              }
          }
          if(data.section==='head') {
            if(data.row.index ===1) {
              data.cell.styles.fontStyle = 'bold';
              data.cell.styles.fillColor = '#257700';
            }
          }
        },
      });
    }
    

  }

  parseaddAntennaTables(){
    doc.addPage();

    // doc.setFontSize(30);
    // doc.text('ANTENNA DATA', 68, 40); //x,y
    doc.setFontSize(16);
    doc.text('ANTENNA DATA', width/2, 40, { align: 'center' });

    // let bodyArr =  [
    //   [
    //     '1',
    //     '156.64',
    //     'Leg A',
    //     '8.29 x 0.45 x 1.4',
    //     'Panel',
    //     '-To be Calculated',
    //     '-To be Calculated',
    //     'Good\r'
    //   ],
    //   [
    //     '2',
    //     '126.06',
    //     'Leg A',
    //     '-To be Calcluated',
    //     'Yagi',
    //     '-To be Calculated',
    //     '-NA-',
    //     'Good\r'
    //   ],
    //   [
    //     '3',
    //     '100.4',
    //     'Leg A',
    //     '-To be Calcluated',
    //     'Yagi',
    //     '-To be Calculated',
    //     '-NA-',
    //     'Good'
    //   ]
    // ]
    let bodyArr =  []
    let equipmentListData = this.audit.audit_data[1];
    
    for (let index = 0; index < equipmentListData.length; index++) {
      let equipDetailData = equipmentListData[index].Details;
      // console.log('equipDetailData- ', equipDetailData);
      let legVal = "Leg A"
      if(equipDetailData[1].Value== "Leg 2"){
        legVal = "Leg B";
      }
      else if(equipDetailData[1].Value== "Leg 3"){
        legVal = "Leg C";
      }
      else if(equipDetailData[1].Value== "Leg 4"){
        legVal = "Leg D";
      }

      let tmpVal = (equipDetailData[4].Value)
      if(tmpVal!= '' && tmpVal!= 'null' && tmpVal!= null ){
        tmpVal = (tmpVal).replaceAll('(ft)', '')
        tmpVal = (tmpVal).replaceAll('( ft )', '')
        tmpVal = (tmpVal).replaceAll('ft', '')
        tmpVal = (tmpVal).replaceAll('()', '')
        if (tmpVal.replaceAll(' ', '') == ''){
          tmpVal = 'N/A';
        }
      }

      let tmpVal1 = (equipDetailData[5].Value)
      if(tmpVal1!= '' && tmpVal1!= 'null' && tmpVal1!= null ){
        tmpVal1 = (tmpVal1).replaceAll('(ft)', '')
        tmpVal1 = (tmpVal1).replaceAll('( ft )', '')
        tmpVal1 = (tmpVal1).replaceAll('ft', '')
        tmpVal1 = (tmpVal1).replaceAll('()', '')
        if (tmpVal1.replaceAll(' ', '') == ''){
          tmpVal1 = 'N/A';
        }
      }

      let tmpAziVal = (equipDetailData[6].Value)
      if(tmpAziVal== '' && tmpAziVal== 'N/A' && (((tmpAziVal.toLowerCase()).replace(/ /g, ""))== 'tobeupdated')){
        tmpAziVal== 'To be included in Line Drawings'
      }

      // ""+String(index+1),
      let tmpObj = [
        equipDetailData[0].Value,
        tmpVal1,
        legVal,
        tmpVal,
        equipDetailData[3].Value,
        tmpAziVal,
        equipDetailData[7].Value,
        equipDetailData[8].Value,
      ]
      bodyArr.push(tmpObj);
    }
    // "Antenna Size (h x w x d - Panel) or (Height - Dipole) or (Diameter- Dish) (ft)"
    bodyArr.sort(this.sortFunction);
    console.log('equipArr - ', bodyArr)
    autoTable(doc, {
      head: [['Name', 'Height from ground to antenna centerline (ft)', 'Leg', 'Antenna Size (h x w x d - Panel) or (Height - Dipole) or (Diameter- Dish) (ft)', 'Antenna Type', 'Azimuth (degree)', 'Antenna Condition', 'Feeder Condition']],
      body: bodyArr,
      columnStyles: {
        0: {cellWidth: 25},
        1: {cellWidth: 27},
        2: {cellWidth: 16},
        3: {cellWidth: 32},
        4: {cellWidth: 22},
        5: {cellWidth: 24},
        6: {cellWidth: 23},
        7: {cellWidth: 23}
      },
      theme: 'grid',
      styles: {
        fontSize: 12,
        minCellHeight: 12
      },
      margin: { left: 9, top: 55 },
      didParseCell(data) {
        if(data.section==='body') {
          // #257700
            if(data.column.index ===0) {
              // data.cell.styles.fontStyle = 'bold';
            }
            // data.cell.styles.textColor = '#000000';
        }
        if(data.section==='head') {
          if(data.row.index ===0) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = '#257700';
          }
        }
      },
    });
    if(bodyArr.length == 0){
      doc.setFontSize(16);
      doc.text('To be Updated', width/2, 110, { align: 'center' });
    }
  }

  sortFunction(a, b) {
    if (a[0] === b[0]) {
        return 0;
    }
    else {
        return (a[0] < b[0]) ? -1 : 1;
    }
  }

  sortFunction2(a, b) {
    if (a.Name === b.Name) {
        return 0;
    }
    else {
        return (a.Name < b.Name) ? -1 : 1;
    }
  }

  async addSitePictures(){

    let allTowerPicsList = this.audit.audit_data[0][12].Details;
    console.log('allTowerPicsList - ', allTowerPicsList);
    
    let fullTowerView = [];
    let sitePicturesList = [];
    let losPicturesLegList = [];
    let losPicturesFaceList = [];
    let towerDetailsList = [];


    for (let index = 0; index < allTowerPicsList.length; index++) {
      if(allTowerPicsList[index].Name == "Site Pictures" && (allTowerPicsList[index].Value).includes("Full Tower View")){
        // console.log('summary Image - ', this.audit.audit_data[0][1].Details[0]);
        
        if((allTowerPicsList[index].Image).length > 0){
          fullTowerView.push(allTowerPicsList[index])
        }else{
          fullTowerView.push({Option: 1, Name: "Site Pictures", Image: [this.audit.audit_data[0][1].Details[0].Image[0]], Value: "Full Tower View"})
        }
      }
      if(allTowerPicsList[index].Name == "Site Pictures" && (allTowerPicsList[index].Value).includes("Site View")){
        sitePicturesList.push(allTowerPicsList[index])
      }
      if(allTowerPicsList[index].Name == "Line of Sight Pictures" && (allTowerPicsList[index].Value).includes("Leg")){
        losPicturesLegList.push(allTowerPicsList[index])
      }
      if(allTowerPicsList[index].Name == "Line of Sight Pictures" && (allTowerPicsList[index].Value).includes("Face")){
        losPicturesFaceList.push(allTowerPicsList[index])
      }
      if(allTowerPicsList[index].Name == "Tower Details" && (allTowerPicsList[index].Image.length > 0)){
        towerDetailsList.push(allTowerPicsList[index])
      }
    }

    console.log('towerDetailsList - ', towerDetailsList);
    

    // Add FullTower View
    // Later need to resize & Crop // If not present
    doc.addPage();

    doc.setFontSize(14);
    // doc.text('SITE PICTURES', 68, 40); //x,y
    doc.text('SITE PICTURES', 83, 12); //x,y
    printObjSubStatus = 'Adding Tower view';
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    var img1 = this.audit.audit_data[0][1].Details[0].Image[0];
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    let compressed = String(await compressImageCover(resNw));
    doc.addImage(compressed, 'JPEG', 20, 20, width-40, height-40);
    doc.text('Tower View', 92, height-13); //x,y

    // Add Site Views - Leg & Face
    doc.addPage();
    await this.add3Images(sitePicturesList, 'Site View 1', 'Site View 2', 'Site View 3');
    doc.addPage();
    doc.text('LINE OF SIGHT PICTURES', 78, 12); //x,y
    await this.add3Images(losPicturesLegList, 'Leg A', 'Leg B', 'Leg C');
    doc.addPage();
    await this.add3Images(losPicturesFaceList, 'Face AB', 'Face BC', 'Face CA'); 

    // Add equipmentList Images
    await this.addequipmentListImages();

    doc.addPage();
    doc.text('TOWER DETAILS', width/2, 12, { align: 'center' });
    for (let index = 0; index < towerDetailsList.length; index=index+3) {
      let numElmLeft = towerDetailsList.length - index
      console.log('numElmLeft - ', numElmLeft);
      if(numElmLeft>3){
        numElmLeft = 3;
      }
      if(index != 0){
        doc.addPage();
      }
      await this.add3TowerDetailsImages(towerDetailsList, index, numElmLeft)
    }

  }

  async addequipmentListImages(){
    let equipmentDataInitial = this.audit.audit_data[1];
    // console.log('equipmentData - ', equipmentDataInitial);
    let equipmentData = [];
    for (let currIndex = 0; currIndex < equipmentDataInitial.length; currIndex=currIndex+1) {
      if(equipmentDataInitial[currIndex].Details[0].Image.length > 0){
        equipmentData.push(equipmentDataInitial[currIndex]);
      }
    }

    equipmentData.sort(this.sortFunction2);
    console.log('equipmentData - ', equipmentData);
    for (let index = 0; index < equipmentData.length; index=index+3) {
      let numElmLeft = equipmentData.length - index
      console.log('numElmLeft - ', numElmLeft);
      if(numElmLeft>3){
        numElmLeft = 3;
      }
      doc.addPage();
      // doc.text('EQUIPMENT PICTURES', 83, 12); //x,y
      doc.text('EQUIPMENT PICTURES', width/2, 12, { align: 'center' });
      await this.addAntennaImages(equipmentData, index, numElmLeft)
    }
  }

  async addAntennaImages(sitePicturesArr, currIndex, toPrint){
    // doc.addPage();

    let multiFactorWidth = 0.85;
    let divisionFactorHeight = 4;
    let startX = 16;
    let startY = 20;
    let diffY = 15;
    let textDiff = 3;
    let resizeDivision = 4.5;

    // console.log('sitePicturesArr - ', sitePicturesArr);
    if (toPrint> 0){
      console.log('Adding Antenna - ', (currIndex+1));
      printObjSubStatus = 'Adding Antenna - '+(currIndex+1);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      var img1 = sitePicturesArr[currIndex].Details[0].Image[0];
      let resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      // let compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
      doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
      doc.setFontSize(13);
      // doc.text(sitePicturesArr[currIndex].Value, startX, 100);
      doc.text(sitePicturesArr[currIndex].Details[0].Value, width/2, 100, { align: 'center' });
    }

    if (toPrint> 1){
      console.log('Adding Antenna - ', (currIndex+2));
      printObjSubStatus = 'Adding Antenna - '+(currIndex+2);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      img1 = sitePicturesArr[currIndex+1].Details[0].Image[0];
      let resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
      doc.addImage(compressed, 'JPEG', startX, startY+diffY+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
      // doc.text(sitePicturesArr[currIndex+1].Value, startX, 190);
      doc.text(sitePicturesArr[currIndex+1].Details[0].Value, width/2, 190, { align: 'center' });
    }

    if (toPrint> 2){
      console.log('Adding Antenna - ', (currIndex+3));
      printObjSubStatus = 'Adding Antenna - '+(currIndex+3);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      img1 = sitePicturesArr[currIndex+2].Details[0].Image[0];
      let resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
      doc.addImage(compressed, 'JPG', startX, startY+(2*diffY)+(2*(height/divisionFactorHeight)), width*multiFactorWidth, height/divisionFactorHeight);
      // doc.text(sitePicturesArr[currIndex+2].Value, startX, 280);
      doc.text(sitePicturesArr[currIndex+2].Details[0].Value, width/2, 280, { align: 'center' });
    }

  }

  async add4SiteViews(sitePicturesArr, head1, head2, head3, head4){

    let multiFactorWidth = 0.4;
    let divisionFactorHeight = 2.7;
    let startX = 16;
    let startY = 25;
    let diffY = 15;
    let diffX = 15;
    let resizeDivision = 4.5;
    

    var img1 = sitePicturesArr[0].Image[0];
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
    doc.setFontSize(13);
    doc.text(head1, 45, 140);
    console.log('Adding - SiteView1');

    img1 = sitePicturesArr[1].Image[0];
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    doc.addImage(compressed, 'JPEG', startX + diffX + (width*multiFactorWidth), startY, width*multiFactorWidth, height/divisionFactorHeight);
    doc.text(head2, 145, 140);
    console.log('Adding - SiteView2');
    
    img1 = sitePicturesArr[2].Image[0];
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    doc.addImage(compressed, 'JPEG', startX, startY+(diffY)+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
    doc.text(head3, 45, 265);
    console.log('Adding - SiteView3');

    img1 = sitePicturesArr[3].Image[0];
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    doc.addImage(compressed, 'JPEG', startX + diffX + (width*multiFactorWidth), startY+(diffY)+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
    doc.text(head4, 145, 265);

    console.log('Adding - SiteView4');
  }

  async add3TowerDetailsImages(sitePicturesArr, currIndex, toPrint){
    // doc.addPage();

    let multiFactorWidth = 0.85;
    let divisionFactorHeight = 4;
    let startX = 16;
    let startY = 20;
    let diffY = 15;
    let textDiff = 3;
    let resizeDivision = 4.5;

    // console.log('sitePicturesArr - ', sitePicturesArr);
    
    console.log('Adding TowerDetails - ', (currIndex+1));
    printObjSubStatus = 'Adding - '+(currIndex+1);
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    var img1 = sitePicturesArr[currIndex].Image[0];
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // let compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
    doc.setFontSize(13);
    // doc.text(sitePicturesArr[currIndex].Value, startX, 100);
    doc.text(sitePicturesArr[currIndex].Value, width/2, 100, { align: 'center' });

    if (toPrint> 1){
      console.log('Adding TowerDetails - ', (currIndex+2));
      printObjSubStatus = 'Adding - '+(currIndex+2);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      img1 = sitePicturesArr[currIndex+1].Image[0];
      resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
      doc.addImage(compressed, 'JPEG', startX, startY+diffY+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
      // doc.text(sitePicturesArr[currIndex+1].Value, startX, 190);
      doc.text(sitePicturesArr[currIndex+1].Value, width/2, 190, { align: 'center' });
    }

    if (toPrint> 2){
      console.log('Adding TowerDetails - ', (currIndex+3));
      printObjSubStatus = 'Adding - '+(currIndex+3);
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      img1 = sitePicturesArr[currIndex+2].Image[0];
      resNw = String(await getBase64ImageFromUrl000123(img1));
      if(resNw == 'error'){
        printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
        this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
      }
      compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
      // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
      doc.addImage(compressed, 'JPG', startX, startY+(2*diffY)+(2*(height/divisionFactorHeight)), width*multiFactorWidth, height/divisionFactorHeight);
      // doc.text(sitePicturesArr[currIndex+2].Value, startX, 280);
      doc.text(sitePicturesArr[currIndex+2].Value, width/2, 280, { align: 'center' });
    }

  }


  async add3Images(sitePicturesArr, head1, head2, head3){
    // doc.addPage();

    let multiFactorWidth = 0.85;
    let divisionFactorHeight = 4;
    let startX = 16;
    let startY = 20;
    let diffY = 15;
    let textDiff = 3;
    let resizeDivision = 4.5;

    // console.log('sitePicturesArr - ', sitePicturesArr);
    
    console.log('Adding - ', head1);
    printObjSubStatus = 'Adding - '+(head1);
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    var img1 = sitePicturesArr[0].Image[0];
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // let compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
    
    doc.setFontSize(13);
    doc.text(head1, startY+(height/divisionFactorHeight), 100);


    console.log('Adding - ', head2);
    printObjSubStatus = 'Adding - '+(head2);
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    img1 = sitePicturesArr[1].Image[0];
    resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY+diffY+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);

    doc.text(head2, startY+(height/divisionFactorHeight), 190);

    console.log('Adding - ', head3);
    printObjSubStatus = 'Adding - '+(head3);
    this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    img1 = sitePicturesArr[2].Image[0];
    resNw = String(await getBase64ImageFromUrl000123(img1));
    if(resNw == 'error'){
      printObjSubStatus = printObjSubStatus+ ' -- ERROR!!!';
      this.dialogRef.componentInstance.data = {name: printObjStatus, animal: printObjSubStatus};
    }
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPG', startX, startY+(2*diffY)+(2*(height/divisionFactorHeight)), width*multiFactorWidth, height/divisionFactorHeight);

    doc.text(head3, startY+(height/divisionFactorHeight), 280);

  }

  async addLosSideViews(sitePicturesArr){
    // doc.addPage();

    let multiFactorWidth = 0.85;
    let divisionFactorHeight = 4;
    let startX = 16;
    let startY = 20;
    let diffY = 15;
    let textDiff = 3;
    let resizeDivision = 4.5;

    var img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.BAL.BURWASH/CV/DJI_0839.JPG'
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // let compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');
    
    doc.setFontSize(13);
    doc.text('Leg A', startY+(height/divisionFactorHeight), 100);


    img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ALB.GEIKIE/FTV/DJI_0002.JPG'
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY+diffY+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');

    doc.text('Leg B', startY+(height/divisionFactorHeight), 190);

    img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ABE.LANGHAM/CV/DJI_0895.JPG'
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPG', startX, startY+(2*diffY)+(2*(height/divisionFactorHeight)), width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');

    doc.text('Leg C', startY+(height/divisionFactorHeight), 280);

  }

  async addLosFaceViews(sitePicturesArr){
    // doc.addPage();

    let multiFactorWidth = 0.85;
    let divisionFactorHeight = 4;
    let startX = 16;
    let startY = 20;
    let diffY = 15;
    let textDiff = 3;
    let resizeDivision = 4.5;

    var img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.BAL.BURWASH/CV/DJI_0839.JPG'
    let resNw = String(await getBase64ImageFromUrl000123(img1));
    let compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // let compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY, width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');
    
    doc.setFontSize(13);
    doc.text('Face AB', startY+(height/divisionFactorHeight), 100);


    img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ALB.GEIKIE/FTV/DJI_0002.JPG'
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPEG', startX, startY+diffY+(height/divisionFactorHeight), width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');

    doc.text('Face BC', startY+(height/divisionFactorHeight), 190);

    img1 = 'https://aerodyne-pwc-telco.s3.amazonaws.com/towers_data/TWR.ABE.LANGHAM/CV/DJI_0895.JPG'
    resNw = String(await getBase64ImageFromUrl000123(img1));
    compressed = String(await compressImage(resNw, (5472/resizeDivision), (3648/resizeDivision)));
    // compressed = String(await compressImage(resNw, width*multiFactorWidth, height/divisionFactorHeight));
    doc.addImage(compressed, 'JPG', startX, startY+(2*diffY)+(2*(height/divisionFactorHeight)), width*multiFactorWidth, height/divisionFactorHeight);
    console.log('Adding - SiteViews');

    doc.text('Face CA', startY+(height/divisionFactorHeight), 280);

  }


  getBase64ImageFromURL(url: string) {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    // This will draw image    
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }


}



async function compressImage(src, newX, newY) {
  let promise = new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, newX, newY);
      const data = ctx.canvas.toDataURL();
      res(data);
    }
    img.onerror = error => rej(error);
  })

  let result = await promise;

  return result
}

async function compressImageCover(src) {
  let promise = new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      let imgNatHt = parseFloat(String(img.naturalHeight));
      let imgNatWd = parseFloat(String(img.naturalWidth));
      // console.log('wid - ', img.naturalWidth, img.naturalHeight );
      
      let newX = ((width*imgNatHt)/height)
      let oriX = (imgNatWd - newX)/2;
      let oriY = 0;
      let newY = imgNatHt;

      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      // console.log('oriX, oriY, newX, newY - ', oriX, oriY, newX, newY);
      
      ctx.drawImage(img, oriX, oriY, newX, newY, 0, 0, newX, newY);

      const data = ctx.canvas.toDataURL();
      res(data);
    }
    img.onerror = error => rej(error);
  })

  let result = await promise;

  return result
}

async function compressImage22(src, oriX, oriY, newX, newY) {
  let promise = new Promise((res, rej) => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = newX;
      elem.height = newY;
      const ctx = elem.getContext('2d');
      // ctx.drawImage(img, oriX, oriY, newX, newY);
      console.log('wid - ', img.naturalWidth, img.naturalHeight );
      
      ctx.drawImage(img, oriX, oriY, newX, newY, 0, 0, newX, newY);
      // ctx.filter = 'grayscale(1)';

      let imgData = (ctx.getImageData(0,0, ctx.canvas.width, ctx.canvas.height));
      let imgDataDta = (ctx.getImageData(0,0, ctx.canvas.width, ctx.canvas.height)).data;


      for (var i = 0; i < imgDataDta.length; i += 4) {
        var brightness = 0.34 * imgData[i] + 0.5 * imgDataDta[i + 1] + 0.16 * imgDataDta[i + 2]
        imgDataDta[i] = brightness
        imgDataDta[i + 1] = brightness
        imgDataDta[i + 2] = brightness
      }



      // for (let i = 0; i < imgData.data.length; i += 4) {
      //   let count = imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
      //   let colour = 0;
      //   if (count > 510) colour = 255;
      //   else if (count > 255) colour = 127.5;
    
      //   imgData.data[i] = colour;
      //   imgData.data[i + 1] = colour;
      //   imgData.data[i + 2] = colour;
      //   imgData.data[i + 3] = 255;
      // }


      // This loop gets every pixels on the image and
        // for (let j=0; j<imageData.height; j++)
        // {
        //   for (let i=0; i<imageData.width; i++)
        //   {
        //     var index=(i*4)*imageData.width+(j*4);
        //     var red=imageData.data[index];
        //     var green=imageData.data[index+1];
        //     var blue=imageData.data[index+2];
        //     var alpha=imageData.data[index+3];
        //     var average=(red+green+blue)/3;
        //     imageData.data[index]=average;
        //     imageData.data[index+1]=average;
        //     imageData.data[index+2]=average;
        //     imageData.data[index+3]=alpha;
        //   }
        // }



      // let imgData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
      // let pixels = imgData.data;
      // for (var i = 0; i < pixels.length; i += 4) {

      //   let lightness = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);

      //   pixels[i] = lightness;
      //   pixels[i + 1] = lightness;
      //   pixels[i + 2] = lightness;
      // }


      ctx.putImageData(imgData, 0, 0);

      const data = ctx.canvas.toDataURL();
      res(data);
    }
    img.onerror = error => rej(error);
  })

  let result = await promise;

  return result
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

async function getBase64ImageFromUrlLocal(imageUrl) {
  
  const proxyUrl = '';

  var res = await fetch(proxyUrl+imageUrl, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } });
  var blob = await res.blob();

  let promise = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.addEventListener("load", function () {
      resolve(reader.result);
    }, false);

    reader.onerror = () => {
      return reject(this);
    };
    reader.readAsDataURL(blob);
  })
  let result = await promise;

  return result
}

async function getBase64ImageFromUrl00012345(imageUrl) {
  
  let proxyUrl = 'https://proxy.aerodyne.dev/';
  // proxyUrl = '';

  // const path = "https://aerodyne-pwc-telco.s3.amazonaws.com/HQ%20Processing/DM/TWR.KIN.COBOURG.26458/Defect%20Marking/5.%20Structure%20Summary/DJI_0113.JPG";
  // const blobToBase64 = blob => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   return new Promise(resolve => {
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     };
  //   });
  // };

  // fetch(proxyUrl+imageUrl, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } })
  //   .then(res => res.blob())
  //   .then(blobToBase64)
  //   .then(base64 => {
  //     console.log("BASE64: ", base64);
  //   });

  // fetch(proxyUrl+imageUrl, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } })
  //   .then(res =>  {
  //     console.log("res.blob(): ", res.blob());
  //   });
  console.log('proxyUrl+imageUrl - ', proxyUrl+imageUrl)
  var res = await fetch(proxyUrl+imageUrl, { method: 'GET', mode:'cors', headers: { "X-Requested-With": "XMLHttpRequest"} });
  // var res = await fetch(imageUrl, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } });
  var blob = await res.blob();
  console.log('blob - ', blob)
  let result = await readFileAsync(blob);
  console.log('result - ', result)

  // var reader = new FileReader();
  // let promise = new Promise((resolve, reject) => {
  //   console.log('here - ')
  //   reader.addEventListener("load", function () {
  //     console.log('here 2 - ')
  //     resolve(reader.result);
  //   }, false);

  //   reader.onerror = () => {
  //     console.log('here 1 - ')
  //     return reject(this);
  //   };
  //   console.log('here 3 - ')
  //   reader.readAsDataURL(blob);
  // })
  // let result = await promise;

  return result
  // return
}

async function getBase64ImageFromUrl000123(imageUrl) {
  
  const proxyUrl = 'https://proxy.aerodyne.dev/';
  // const path = "https://aerodyne-pwc-telco.s3.amazonaws.com/HQ%20Processing/DM/TWR.KIN.COBOURG.26458/Defect%20Marking/5.%20Structure%20Summary/DJI_0113.JPG";
  // const blobToBase64 = blob => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);
  //   return new Promise(resolve => {
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     };
  //   });
  // };

  // fetch(proxyUrl+path, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } })
  //   .then(res => res.blob())
  //   .then(blobToBase64)
  //   .then(base64 => {
  //     console.log("BASE64: ", base64);
  // });

  var res = await fetch(proxyUrl+imageUrl, { method: 'GET', mode: 'cors', headers: { "X-Requested-With": "XMLHttpRequest", "Origin": "http://localhost:4200" } });
  // console.log('res - ', res);
  if(res.status == 200){
    var blob = await res.blob();

    let promise = new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        // console.log('errror - ');
        
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    let result = await promise;
    // console.log('result - ', result);

    return result
  }
  else{
    return 'error'
  }
  
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  })
}