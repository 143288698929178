import { Component, Inject, Input, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuditDetailsComponent, printObjStatus } from '../audit-details/audit-details.component';

@Component({
  selector: 'app-dialog-pdf',
  templateUrl: './dialog-pdf.component.html',
  styleUrls: ['./dialog-pdf.component.css']
})
export class DialogPdfComponent implements OnInit {
  // @Input() data:any;
  
  // data = 'Step 1'
  // data = printObjStatus;

  constructor(private dialogRef: MatDialogRef<DialogPdfComponent>, @Inject(MAT_DIALOG_DATA) public data: AuditDetailsComponent) {
  // constructor(private dialogRef: MatDialogRef<DialogPdfComponent>) {
    dialogRef.disableClose= true;
   }

  ngOnInit(): void {
  }

  triggeredfunc1(){
    console.log('dialog was closed');
    
  }

}
