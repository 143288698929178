import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { callbackify } from 'util';
import { AuditFetchService } from './audit-fetch.service';

const title = 'Car Sell Report';
const header = ["Year", "Month", "Make", "Model", "Quantity", "Pct"]
const datad = [
  // [2007, 1, "Volkswagen ", "Volkswagen Passat", 1267, 10],
  // [2007, 1, "Toyota ", "Toyota Rav4", 819, 6.5],
  // [2007, 1, "Toyota ", "Toyota Avensis", 787, 6.2],
  // [2007, 1, "Volkswagen ", "Volkswagen Golf", 720, 5.7],
  // [2007, 1, "Toyota ", "Toyota Corolla", 691, 5.4],
];

let data1: any;

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private audit: AuditFetchService) { }



  generateExcel0(data) {

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('General');
    let worksheet1 = workbook.addWorksheet('Equipment');

    // Add new row
    let titleRow = worksheet.addRow([]);
    // Set font, size and style in title row.
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    // Add Header Row
    // console.log(data[0][0].Details);

    //Prints general worksheet
    for (let l = 0; l < data[0].length; l++) {
      // Blank Row
      worksheet.addRow([]);

      const title = this.audit.excelBuildHeader(data[0][l].Details, 0);

      worksheet.addRow([])
      worksheet.addRow([data[0][l].Name])
      worksheet.addRow([])

      let headerRow = worksheet.addRow(title);

      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF00' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });

      for (let e0 = 0; e0 < data[0][l].Details.length; e0++) {
        let obj1 = []
        for (let d0 = 0; d0 < title.length; d0++) {
          if (data[0][l].Details[e0].hasOwnProperty(title[d0])) {
            obj1.push(data[0][l].Details[e0][title[d0]])
          }
        }
        worksheet.addRow(obj1)
      }
    }
    // console.log(data[1][0].Details);

    //Prints Equipment
    for (let l = 0; l < data[1].length; l++) {
      // Blank Row
      worksheet1.addRow([]);

      const title = this.audit.excelBuildHeader(data[1][l].Details, 0);
      console.log(title);

      worksheet1.addRow([])

      let TableName = worksheet1.addRow([data[1][l].Name])

      TableName.eachCell((cell, Number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '80888880' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      })

      worksheet1.addRow([])

      let headerRow = worksheet1.addRow(title);

      // Cell Style : Fill and Border
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '20FFFF80' },
          bgColor: { argb: 'FF0000FF' }
        }
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      });

      for (let e0 = 0; e0 < data[1][l].Details.length; e0++) {
        let obj1 = []
        for (let d0 = 0; d0 < title.length; d0++) {
          if (data[1][l].Details[e0].hasOwnProperty(title[d0])) {
            obj1.push(data[1][l].Details[e0][title[d0]])
          }
        }
        worksheet1.addRow(obj1)
      }
    }

    workbook.xlsx.writeBuffer().then((datad) => {
      let blob = new Blob([datad], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'AuditData.xlsx');
    });


  }

  generateExcel(data) {
    // console.log('data - ', data);
    let originalGeneralObj = data[0];
    let originalEquipObj = data[1];
    let towerNm = originalGeneralObj[1].Details[0].Value;
    console.log('towerNm - ', towerNm);
    
    
    if(originalEquipObj.length>0){
      let finalXlsxData = []

      for (let index = 0; index < originalEquipObj.length; index++) {
        let equipObj = originalEquipObj[index].Details;
        // console.log('equipObj - ', equipObj);
        // console.log('equipObj - ', equipObj[5]);
        // console.log('equipObj - ', equipObj[5].Value);

        let legVal = "Leg A"
        if (equipObj[1].Value == "Leg 2") {
          legVal = "Leg B";
        }
        else if (equipObj[1].Value == "Leg 3") {
          legVal = "Leg C";
        }
        else if (equipObj[1].Value == "Leg 4") {
          legVal = "Leg D";
        }


        let tmpVal = (equipObj[4].Value.toString())
        tmpVal = tmpVal.replace(/(ft)/g, "");
        tmpVal = tmpVal.replace(/( ft )/g, "");
        tmpVal = tmpVal.replace(/ft/g, "");
        tmpVal = tmpVal.replace(/()/g, "");
        if (tmpVal.replace(/ /g, "") == '') {
          tmpVal = 'N/A';
        }

        let tmpVal1 = (equipObj[5].Value.toString())
        tmpVal1 = tmpVal1.replace(/(ft)/g, "");
        tmpVal1 = tmpVal1.replace(/( ft )/g, "");
        tmpVal1 = tmpVal1.replace(/ft/g, "");
        tmpVal1 = tmpVal1.replace(/()/g, "");
        if (tmpVal1.replace(/ /g, "") == '') {
          tmpVal1 = 'N/A';
        }


        // 'Name', 'Height from ground to antenna centerline (ft)', 'Leg', 'Antenna Size (h x w x d - Panel) or (Height - Dipole) or (Diameter- Dish) (ft)', 'Antenna Type', 'Azimuth (degree)', 'Antenna Condition', 'Feeder Condition'
        let tmpObj = [];
        tmpObj.push(equipObj[0].Value);
        tmpObj.push(tmpVal1);
        tmpObj.push(legVal);
        tmpObj.push(tmpVal);
        tmpObj.push(equipObj[3].Value);
        tmpObj.push('To be included in Line Drawings');
        tmpObj.push(equipObj[7].Value);
        tmpObj.push(equipObj[8].Value);



        finalXlsxData.push(tmpObj)
      }
      // console.log('towerNm - ', towerNm);

      if (finalXlsxData.length > 0) {
        
        finalXlsxData.sort(this.sortFunction);
        console.log('finalXlsxData - ', finalXlsxData);

        const results = finalXlsxData;

        // https://www.anandk.dev/2020/11/Create-excel-on-ibmi.html
        // Create Excel workbook and worksheet
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Customers');
        // Define columns in the worksheet, these columns are identified using a key.
        worksheet.columns = [
          { header: 'Name', key: 'CUSNUM', width: 10 },
          { header: 'Height from ground to antenna centerline (ft)', key: 'LSTNAM', width: 40 },
          { header: 'Leg', key: 'BALDUE', width: 11 },
          { header: 'Antenna Size (h x w x d - Panel) or (Height - Dipole) or (Diameter- Dish) (ft)', key: 'CDTLMT', width: 30 },
          { header: 'Antenna Type', key: 'CDTLMT', width: 15 },
          { header: 'Antenna (degree)', key: 'CDTLMT', width: 30 },
          { header: 'Antenna Condition', key: 'CDTLMT', width: 20 },
          { header: 'Feeder Condition', key: 'CDTLMT', width: 20 }
        ]

        // Add rows from database to worksheet 
        for (const row of results) {
          worksheet.addRow(row);
        }

        worksheet.eachRow(function (row, rowNumber) {

          row.eachCell((cell, colNumber) => {
            cell.font = {
              name: 'Calibri'
            };
            //   family: 2,
            //   bold: false,
            //   size: 10,

            if (rowNumber == 1) {
              // First set the background of header row
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '257700' }
              }
              cell.font = {
                name: 'Calibri',
                color: { argb: 'ffffff' }
              }
            }
            // #217346; #f5b914; #257700
            // Set border of each cell 
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          })
          //Commit the changed row to the stream
          row.commit();
        });

        console.log('printing excel ... ');

        // Finally save the worksheet into the folder from where we are running the code. 
        // await workbook.xlsx.writeFile('pwc_output_data/' + towerNm + '_excelsheet.xlsx');

        workbook.xlsx.writeBuffer().then((datad) => {
          let blob = new Blob([datad], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fs.saveAs(blob, (towerNm+'_excelsheet.xlsx'));
        });

      }

    }
  }

  sortFunction(a, b) {
    if (a[0] === b[0]) {
        return 0;
    }
    else {
        return (a[0] < b[0]) ? -1 : 1;
    }
  }
}
