import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GalleryFetchService {


  constructor(private fireStore : AngularFirestore, private snackBar: MatSnackBar) {
   }


  fetchData(towerName){
    return this.fireStore.collection(towerName).doc(towerName).get();
}
setData(tower){
  return  this.fireStore.collection(tower).doc(tower);
  }








}
