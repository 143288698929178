<mat-card class="signIncard">
  <mat-card-header>
    <mat-card-title>Sign In</mat-card-title>
    <mat-card-subtitle>Enter your Details to Login</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="signInForm">
      <mat-form-field appearance="fill">
        <mat-label>Enter your Email</mat-label>
        <input matInput name="email" type="email" #email required>
        <button mat-icon-button matSuffix>
          <mat-icon>email</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Enter your password</mat-label>
        <input matInput [type]="hide ? 'password' : 'text'"  minlength="6" maxlength="25" #password required>
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="else">
      <div class="forgotPassword">
        <span class="redirect" routerLink="/forgot-password">Forgot Password?</span>
      </div>
    <!-- <div class="notRegistered">
      <span>Not registered yet?<span class="redirect" routerLink="/sign-up"> Register</span></span>
    </div> -->
    </div>
  </mat-card-content>
  <mat-card-actions (click)="signin(email.value,password.value)">
<button mat-raised-button color="primary" class="signInBtn" (click)="signin(email.value,password.value)">Login</button>
  </mat-card-actions>
</mat-card>
